import { MembershipTemplate } from '@entrecode/dm-types/types/MembershipTemplate';
import AssetResource from 'ec.sdk/lib/resources/datamanager/AssetResource';

export const emptyMembershipTemplate: (legals, consents) => Partial<MembershipTemplate & { _modelTitle?: string }> = (
  legals = [],
  consents = [],
) => {
  return {
    name: '',
    versions: [],
    active: {
      mainAbo: null,
      benefits: [
        {
          title: '',
          description: '',
        },
      ],
      fees: [],
      addons: [],
      mainText: [
        {
          title: '',
          body: '',
        },
      ],
      abosAtEnd: [],
      abosParallel: [],
      trialPeriod: 'P2W',
      mainInterval: 'P1M',
      mainDuration: 'P1M',
      abosAfterTrial: [],
      hectorSettings: {} as any,
      discountDuration: 'P0M',
      discountInterval: 'P1M',
      consents: consents,
      contractTemplates: [],
      voucherTemplates: [],
      legalTemplates: legals,
      mainIntervalPrice: 0,
      minimumMembershipAge: 14,
      discountIntervalPrice: null,
      draftStatus: 'Entwurf',
    } as any,
    draft: null,
    backendId: '',
  };
};

export const emptyAddonTemplate: (
  legals,
  consents,
) => Partial<
  MembershipTemplate & {
    description: string;
    images: AssetResource[];
    salesDescription: string;
    profileActive: boolean;
    upsellingActive: boolean;
    terminalActive: boolean;
    _modelTitle?: string;
  }
> = (legals = [], consents = []) => ({
  name: '',
  versions: [],
  description: '',
  images: [],
  salesDescription: '',
  profileActive: false,
  upsellingActive: false,
  terminalActive: false,
  active: {
    mainAbo: null,
    benefits: [
      {
        title: '',
        description: '',
      },
    ],
    fees: [],
    addons: [],
    mainText: [
      {
        title: '',
        body: '',
      },
    ],
    abosAtEnd: [],
    abosParallel: [],
    trialPeriod: 'P4W',
    mainInterval: 'P1M',
    mainDuration: 'P1M',
    abosAfterTrial: [],
    hectorSettings: {} as any,
    discountDuration: 'P0M',
    discountInterval: 'P1M',
    contractTemplates: [],
    voucherTemplates: [],
    legalTemplates: legals,
    consents: consents,
    mainIntervalPrice: 0,
    minimumMembershipAge: 14,
    discountIntervalPrice: null,
    draftStatus: 'Entwurf',
  } as any,
  draft: null,
  backendId: '',
});

async function createNewTemplate(api, search) {
  // create Routen / noch kein template vorhanden
  const backend = search.get('backend');
  const legals = await api.entryList('eva_template', {
    type: 'markdown',
    name: { any: [`${backend}$markdown-AGB`, `${backend}$markdown-SEPA`, `${backend}$markdown-PRIVACY`] },
  });
  const emptytemplate = { ...emptyMembershipTemplate };
  emptytemplate.active.legalTemplates = legals.getAllItems();
  return emptytemplate;
}
