import Badge from '@/components/Badge';
import Button from '@/components/Button';
import Form from '@/components/Form';
import SimpleSelect from '@/components/SimpleSelect';
import { OMTContext } from '@/hooks/onlineMembershipTemplates/omtContext';
import useFeedback from '@/hooks/useFeedback';
import useIsMigrating from '@/hooks/useIsMigrating';
import useNotifications from '@/hooks/useNotifications';
import useSdk from '@/hooks/useSdk';
import { TabsContext } from '@/routes/OnlineMembershipTemplateDetail';
import { Preview } from '@/routes/TemplateDesigner/components/Preview';
import { MEMBERSHIP } from '@/routes/TemplateDesigner/model';
import { classNames } from '@/util/classNames';
import ArrowDownOnSquareIcon from '@heroicons/react/24/outline/ArrowDownOnSquareIcon';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { Control, Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import useSWR from 'swr';
import AssetPickerMulti from '../AssetPickerMulti';
import ConfirmDelete from '../ConfirmDelete';
import { useGlobalContractPreviewImage } from '../GlobalContract/useGlobalContractPreview';
import Spinner from '../Spinner';
import { PDFPreviewModal, usePDFPreview } from './PDFPreviewModal';
import useConfirm from '@/hooks/useConfirm';

export const ContractForm = ({ type }) => {
  const { selectedVersion, isVoucher, isAddon, mutate, userCan, membershipTemplate, templateID } =
    useContext(OMTContext);

  const { confirmUser, ConfirmProvider } = useConfirm();
  const form = useForm({
    defaultValues: { ...selectedVersion } as any,
  });

  useEffect(() => {
    form.reset({ ...selectedVersion });
  }, [selectedVersion, form]);

  const { api } = useSdk();

  const { data: legalTemplates } = useSWR(api ? `legalTemplates/${templateID}` : null, async () => {
    return (await api.entryList('eva_template', { type: 'markdown', _count: 999 })).getAllItems();
  });

  const { setCanChange } = useContext(TabsContext);
  const isDirty = form.formState.isDirty;
  useEffect(() => {
    setCanChange(!isDirty);
  }, [isDirty, setCanChange]);

  const { withFeedback, pending } = useFeedback();
  const onSubmit = (values) =>
    withFeedback(
      async () => {
        selectedVersion.legalTemplates = values.legalTemplates?.map((item) => item.id) ?? [];
        selectedVersion.attachments = values.attachments ?? [];
        selectedVersion.consents = values.consents ?? [];
        //@ts-ignore
        await selectedVersion.save();
        setCanChange(true);
        await mutate();
      },
      {
        success: 'Änderungen gespeichert',
        error: 'Änderungen konnten nicht gespeichert werden',
      },
    );

  const navigate = useNavigate();

  const { withFeedback: createFeedback } = useFeedback();

  function makeContract() {
    createFeedback(
      async () => {
        let template;
        const name = `${membershipTemplate?.backendId}$contract-${selectedVersion?.id}`;
        try {
          template = (
            await api.entryList('eva_template', {
              name,
              type: 'pdf',
            })
          ).getFirstItem();
        } catch (e) {
          template = await api.createEntry('eva_template', {
            name,
            backendID: membershipTemplate?.backendId,
            type: 'pdf',
            description: `Vertrag für ${isVoucher ? 'Gutschein' : isAddon ? 'Addon' : 'Abo'}vorlage ${
              membershipTemplate?.name
            }`,
            content: {
              fabric: {
                objects: [],
                background: 'white',
              },
              settings: {
                lang: 'de',
                width: 1240,
                height: 1754,
              },
            },
          });
        }

        selectedVersion.contractTemplates.push(template as any);
        (selectedVersion as any).save();

        navigate({
          pathname: `../../contracts/${name}`,
          search: `type=${isVoucher ? 'voucher' : isAddon ? 'addon' : 'contract'}`,
        });
      },
      { success: 'Vertrag erstellt', error: 'Vertrag konnte nicht erstellt werden' },
    );
  }

  function assignDefaultContract() {
    createFeedback(
      async () => {
        const global_contract = (
          await api.entryList('eva_template', {
            type: 'global_contract',
            name: isAddon ? 'GlobalAddon' : 'GlobalContract',
          })
        ).getFirstItem();
        selectedVersion.contractTemplates.push(global_contract as any);
        await (selectedVersion as any).save();
        mutate();
        form.setValue('membership_template_version.contractTemplates.0', global_contract, {
          shouldDirty: true,
        });
      },
      {
        success: 'Globale Vorlage zugewiesen',
        error: 'Globale Vorlage konnte nicht zugewiesen werden',
      },
    );
  }

  function makeVoucher() {
    createFeedback(
      async () => {
        let template;
        try {
          template = (
            await api.entryList('eva_template', {
              name: `${membershipTemplate?.backendId}$voucher-${selectedVersion?.id}`,
              type: 'pdf',
            })
          ).getFirstItem();
        } catch (e) {
          template = await api.createEntry('eva_template', {
            name: `${membershipTemplate?.backendId}$voucher-${selectedVersion?.id}`,
            backendID: membershipTemplate?.backendId,
            type: 'pdf',
            description: `Gutschein für Abovorlage ${membershipTemplate?.name}`,
            content: {
              fabric: {
                objects: [],
                background: 'white',
              },
              settings: {
                lang: 'de',
                width: 1240,
                height: 1754,
              },
            },
          });
        }

        selectedVersion.voucherTemplates.push(template as any);
        (selectedVersion as any).save();

        navigate(`../../contracts/${membershipTemplate?.backendId}$voucher-${selectedVersion?.id}`);
      },
      { success: 'Gutschein erstellt', error: 'Gutschein konnte nicht erstellt werden' },
    );
  }

  function assignDefaultVoucher() {
    createFeedback(
      async () => {
        const global_contract = (
          await api.entryList('eva_template', { type: 'global_contract', name: 'GlobalVoucher' })
        ).getFirstItem();
        selectedVersion.voucherTemplates.push(global_contract as any);
        await (selectedVersion as any).save();
        mutate();
        form.setValue('membership_template_version.voucherTemplates.0', global_contract, {
          shouldDirty: true,
        });
      },
      {
        success: 'Globale Vorlage zugewiesen',
        error: 'Globale Vorlage konnte nicht zugewiesen werden',
      },
    );
  }

  const uploadContract = () => {
    withFeedback(
      () =>
        new Promise((resolve, reject) => {
          const input = document.createElement('input');
          input.type = 'file';
          input.onchange = async (e) => {
            try {
              const file = (e.target as HTMLInputElement).files[0];

              const content = await file.text();
              const json = JSON.parse(content);
              const template = await api.createEntry('eva_template', {
                name: `${membershipTemplate?.backendId}$contract-${selectedVersion?.id}`,
                backendID: membershipTemplate?.backendId,
                type: 'pdf',
                description: `Vertrag für Abovorlage ${membershipTemplate?.name}`,
                content: {
                  fabric: json.fabric,
                  settings: json.settings,
                },
              });
              selectedVersion.contractTemplates.push(template as any);
              await (selectedVersion as any).save();
              resolve(true);
            } catch (err) {
              reject(err);
            }
          };
          input.click();
          setCanChange(true);
        }),
      {
        success: 'Vertrag hochgeladen',
        error: 'Vertrag konnte nicht hochgeladen werden',
      },
    );
  };

  const uploadVoucher = () => {
    withFeedback(
      () =>
        new Promise((resolve, reject) => {
          const input = document.createElement('input');
          input.type = 'file';
          input.onchange = async (e) => {
            try {
              const file = (e.target as HTMLInputElement).files[0];

              const content = await file.text();
              const json = JSON.parse(content);
              let name = `${membershipTemplate?.backendId}$voucher-${selectedVersion?.id}`;

              const has = await api
                .entry('eva_template', {
                  name,
                  _fields: [],
                })
                .catch(() => null);

              if (has) {
                await has.del();
              }

              const template = await api.createEntry('eva_template', {
                name,
                backendID: membershipTemplate?.backendId,
                type: 'pdf',
                description: `Gutschein für Abovorlage ${membershipTemplate?.name}`,
                content: {
                  fabric: json.fabric,
                  settings: json.settings,
                },
              });
              selectedVersion.voucherTemplates.push(template as any);
              await (selectedVersion as any).save();
              resolve(true);
            } catch (err) {
              reject(err);
            }
          };
          input.click();
          setCanChange(true);
        }),
      {
        success: 'Gutschein hochgeladen',
        error: 'Gutschein konnte nicht hochgeladen werden',
      },
    );
  };

  const { data: previewURLContract, isLoading } = useGlobalContractPreviewImage(isAddon ? 'GlobalAddon' : undefined);
  const { data: previewURLVoucher, isLoading: isLoadingVoucher } = useGlobalContractPreviewImage(
    type === 'voucher' ? 'GlobalVoucher' : null,
  );
  const { handlePreview, setPreview, preview } = usePDFPreview(isAddon ? 'GlobalAddon' : 'GlobalContract');

  const [showChangeTemplate, setShowChangeTemplate] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showDuplicate, setShowDuplicate] = useState(false);
  const [showConfirmVoucher, setShowConfirmVoucher] = useState(false);
  const notification = useNotifications();
  const { data: migrationState } = useIsMigrating();

  function exportData([template]) {
    const { variables } = MEMBERSHIP;
    const pipes = [' | date', ' | currency', ' | interval'];

    if (!template) {
      notification.emit({
        type: 'error',
        message: 'Vorlage nicht gewählt',
      });

      return;
    }

    const m = (o) => {
      o.show = null;
      o.hide = null;

      if (o.type === 'textbox' && o.text) {
        variables.forEach(({ migration, value }) => {
          if (migration) {
            o.text = o.text.replaceAll(value, migration);
          }
        });
      }
      if (!o.fontStyle) {
        o.fontStyle = 'normal';
      }
      if (pipes.some((v) => o.text?.includes(v))) {
        pipes.forEach((p) => {
          o.text = o.text.replaceAll(p, '');
        });
      }
      return o;
    };

    const data = {
      templateId: template.name,
      name: template.description,
      fabric: {
        ...template.content.fabric,
        objects: template.content.fabric.objects.map((o: any) => {
          if (o.type === 'group') {
            o.objects = o.objects.map((g) => m(g));
          }

          return m(o);
        }),
      },
      settings: template.content.settings,
      model: null,
    };

    const json = JSON.stringify(data, null, 2);
    const blob = new Blob([json], { type: 'text/json' });
    const link = document.createElement('a');
    link.classList.add('hidden');
    link.download = `${template.name}.json`;
    link.href = window.URL.createObjectURL(blob);
    link.dataset.downloadurl = ['text/json', link.download, link.href].join(':');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  if (!selectedVersion || !membershipTemplate) return null;
  return (
    <>
      <ConfirmDelete
        open={showChangeTemplate}
        title="Vorlage wirklich wechseln?"
        description="Mit dem Wechsel der Vorlage wird der bestehende Vertrag entfernt und kann nicht mehr aufgerufen werden. Ein Export der Vorlage wird deshalb empfohlen, bevor ein neuer Vertag gewählt wird. Möchtest du den Vertrag jetzt wechseln?"
        deleteLabel="Vertag wechseln"
        onClose={() => setShowChangeTemplate(false)}
        onDelete={async () => {
          const { id, type: t } = selectedVersion.contractTemplates[0];
          const count = await api.refCount('membership_template_version', 'contractTemplates', [id]);
          const isLinkedMultipleTimes = count[id] > 1;
          const isGlobalContract = t === 'global_contract';
          setShowChangeTemplate(false);

          withFeedback(
            async () => {
              selectedVersion.contractTemplates = [];
              await (selectedVersion as any).save();
              if (!isLinkedMultipleTimes) {
                if (['notStarted', 'finished'].includes(migrationState)) {
                  // während der migration Templates nicht löschen, da sonst alte bestehende Live Verträge betroffen sind
                  // im normalen Modus Templates löschen da 1-1 Template - Abo
                  if (!isGlobalContract) {
                    await api.deleteEntry('eva_template', id);
                  }
                }
              }
              mutate();
            },
            {
              success: isLinkedMultipleTimes || isGlobalContract ? 'Vorlage wurde zurückgesetzt' : 'Vorlage entfernt',
              error:
                isLinkedMultipleTimes || isGlobalContract
                  ? 'Vorlage konnte nicht zurückgesetzt werden'
                  : 'Vorlage konnte nicht entfernt werden',
            },
          );
        }}
      />
      <div
        className={classNames(
          'bg-white dark:bg-gray-700 rounded-lg p-4',
          // (!userCan.edit || selectedVersion?.draftStatus === null) && ' pointer-events-none',
        )}
      >
        <Form>
          <Form.Item $first>
            <Form.Item.Label>Hauptvorlage</Form.Item.Label>
            <Form.Item.Body>
              {selectedVersion?.contractTemplates.length > 0 ? (
                selectedVersion?.contractTemplates.map((template, index) => (
                  <div className="flex flex-col gap-3 justify-end" key={index}>
                    <div className="flex gap-3 justify-end">
                      {/* <Button $secondary onClick={() => { }}> */}
                      {/*   Vorschau PDF herunterladen */}
                      {/* </Button> */}
                      {userCan.edit && selectedVersion.draftStatus !== null && (
                        <>
                          <Button $secondary onClick={handlePreview}>
                            Vorschau
                          </Button>
                          <PDFPreviewModal state={[preview, setPreview]} />

                          <Button $secondary onClick={() => setShowChangeTemplate(true)}>
                            Andere Vorlage auswählen
                          </Button>
                          <Button
                            $primary
                            onClick={async () => {
                              if (selectedVersion.contractTemplates[0].type === 'global_contract') {
                                return setShowConfirm(true);
                              }

                              const { id } = selectedVersion.contractTemplates[0];
                              const count = await api.refCount('membership_template_version', 'contractTemplates', [
                                id,
                              ]);
                              const isLinkedMultipleTimes = count[id] > 1;
                              if (isLinkedMultipleTimes) {
                                setShowDuplicate(true);
                                return;
                              }

                              navigate(
                                {
                                  pathname: `../../contracts/${template.name}`,
                                  search: `type=${isVoucher ? 'voucher' : isAddon ? 'addon' : 'contract'}`,
                                },
                                {
                                  state: {
                                    comingFrom: `${
                                      isVoucher ? 'vouchers' : isAddon ? 'addon-templates' : 'onlineMembershipTemplates'
                                    }/${templateID}?tab=2`,
                                  },
                                  replace: true,
                                },
                              );
                            }}
                          >
                            Vorlage bearbeiten
                          </Button>

                          <ConfirmDelete
                            title="Du bearbeitest eine Vorlage, die LIVE ist"
                            description="Du kannst diese Vorlage nicht bearbeiten, da sie auch bei Verträgen verwendet wird, die LIVE sind. Möchtest du eine neue Vorlage erstellen?"
                            deleteLabel="Ja, neue Vorlage erstellen"
                            cancelLabel="Nicht bearbeiten"
                            open={showDuplicate}
                            onClose={() => {
                              return setShowDuplicate(false);

                              // navigate(`../../contracts/${template.name}`, {
                              //   state: {
                              //     comingFrom: `${
                              //       isVoucher ? 'vouchers' : isAddon ? 'addon-templates' : 'onlineMembershipTemplates'
                              //     }/${templateID}?tab=2`,
                              //   },
                              // });
                            }}
                            onDelete={async () => {
                              // create a new template with same content
                              const name = `${membershipTemplate?.backendId}$contract-${selectedVersion?.id}`;
                              const res = await api.createEntry('eva_template', {
                                name: `${membershipTemplate?.backendId}$contract-${selectedVersion?.id}`,
                                backendID: membershipTemplate?.backendId,
                                type: template.type,
                                description: template.description,
                                content: {
                                  fabric: template.content.fabric,
                                  settings: template.content.settings,
                                },
                              });

                              selectedVersion.contractTemplates = [res as any];
                              await selectedVersion.save();

                              navigate(`../../contracts/${name}`, {
                                state: {
                                  comingFrom: `${
                                    isVoucher ? 'vouchers' : isAddon ? 'addon-templates' : 'onlineMembershipTemplates'
                                  }/${templateID}?tab=2`,
                                },
                              });
                            }}
                          />

                          <ConfirmDelete
                            title="Achtung, wirklich bearbeiten"
                            description="Bist du dir sicher, dass du die globale Vertragsvorlage ändern möchtest?"
                            deleteLabel="Ja"
                            open={showConfirm}
                            onClose={() => setShowConfirm(false)}
                            onDelete={() => {
                              template.type === 'global_contract'
                                ? navigate(`../designer?type=${isAddon ? 'GlobalAddon' : 'GlobalContract'}`)
                                : navigate(`../../contracts/${template.name}`, {
                                    state: {
                                      comingFrom: `onlineMembershipTemplates/${templateID}?tab=2`,
                                    },
                                  });
                            }}
                          />
                        </>
                      )}

                      {(!userCan.edit || selectedVersion?.draftStatus === null) &&
                        selectedVersion.contractTemplates[0].type !== 'global_contract' && (
                          <Button onClick={() => exportData(selectedVersion.contractTemplates)}>
                            Vorlage exportieren
                          </Button>
                        )}
                    </div>
                    <div className="flex">
                      {template.type === 'global_contract' ? (
                        <div className="max-w-[300px] border rounded overflow-clip bg-gray-100 relative">
                          {!isLoading ? (
                            previewURLContract ? (
                              <img src={previewURLContract} alt="Global Contract Preview" />
                            ) : (
                              <div className="text-red-600 h-20 flex items-center justify-center text-xs m-6">
                                Vorschau nicht verfügbar
                              </div>
                            )
                          ) : (
                            <Spinner className="w-16 h-16 text-black mx-auto mt-6 mb-6" />
                          )}
                          <small className="m-2 block text-center text-black font-bold">Zentrale Vertragsvorlage</small>
                        </div>
                      ) : (
                        <Preview template={template} onSelect={() => {}} />
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div>
                  <div className="flex flex-col gap-3 mb-3 ">
                    <div className="font-bold text-lg  text-center">Noch kein Vertrag ausgewählt</div>
                    {userCan.edit && (
                      <>
                        <Button $primary onClick={assignDefaultContract}>
                          Standardvertrag benutzen
                        </Button>
                        <Button $secondary onClick={makeContract}>
                          Neuen Vertrag erstellen
                        </Button>
                        <Button $secondary onClick={uploadContract}>
                          <ArrowDownOnSquareIcon className="w-4 h-4" /> Vorlage importieren
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              )}
            </Form.Item.Body>
          </Form.Item>

          {type === 'voucher' && (
            <Form.Item>
              <Form.Item.Label>Gutscheinvorlage</Form.Item.Label>
              <Form.Item.Body>
                {selectedVersion?.voucherTemplates.length > 0 ? (
                  selectedVersion?.voucherTemplates.map((template, index) => (
                    <div className="flex flex-col gap-3 justify-end" key={index}>
                      <div className="flex gap-3 justify-end">
                        {/* <Button $secondary onClick={() => { }}> */}
                        {/*   Vorschau PDF herunterladen */}
                        {/* </Button> */}
                        {userCan.edit && selectedVersion.draftStatus !== null && (
                          <>
                            <Button
                              $secondary
                              onClick={async () => {
                                const { id, type: t } = selectedVersion.voucherTemplates[0];
                                const count = await api.refCount('membership_template_version', 'contractTemplates', [
                                  id,
                                ]);
                                const isLinkedMultipleTimes = count[id] > 1;
                                const isGlobalContract = t === 'global_contract';

                                withFeedback(
                                  async () => {
                                    selectedVersion.voucherTemplates = [];
                                    await (selectedVersion as any).save();
                                    if (!isLinkedMultipleTimes) {
                                      if (['notStarted', 'finished'].includes(migrationState)) {
                                        // während der migration Templates nicht löschen, da sonst alte bestehende Live Verträge betroffen sind
                                        // im normalen Modus Templates löschen da 1-1 Template - Abo
                                        if (!isGlobalContract) {
                                          await api.deleteEntry('eva_template', id);
                                        }
                                      }
                                    }
                                    mutate();
                                  },
                                  {
                                    success:
                                      isLinkedMultipleTimes || isGlobalContract
                                        ? 'Vorlage wurde zurückgesetzt'
                                        : 'Vorlage entfernt',
                                    error:
                                      isLinkedMultipleTimes || isGlobalContract
                                        ? 'Vorlage konnte nicht zurückgesetzt werden'
                                        : 'Vorlage konnte nicht entfernt werden',
                                  },
                                );
                              }}
                            >
                              Andere Vorlage auswählen
                            </Button>
                            <Button
                              $primary
                              onClick={() => {
                                if (selectedVersion.voucherTemplates[0].type === 'global_contract') {
                                  return setShowConfirmVoucher(true);
                                }
                                navigate(
                                  { pathname: `../../contracts/${template.name}`, search: 'type=voucher' },
                                  {
                                    state: {
                                      comingFrom: `vouchers/${templateID}?tab=2`,
                                    },
                                    replace: true,
                                  },
                                );
                              }}
                            >
                              Vorlage bearbeiten
                            </Button>
                            <ConfirmDelete
                              title="Achtung, wirklich bearbeiten"
                              description="Bist du dir sicher, dass du die globale Gutscheinvorlage ändern möchtest?"
                              deleteLabel="Ja"
                              open={showConfirmVoucher}
                              onClose={() => setShowConfirmVoucher(false)}
                              onDelete={() => {
                                template.type === 'global_contract'
                                  ? navigate('../designer?type=GlobalVoucher')
                                  : navigate(`../../contracts/${template.name}`, {
                                      state: {
                                        comingFrom: `vouchers/${templateID}?tab=2`,
                                      },
                                    });
                              }}
                            />
                          </>
                        )}

                        {(!userCan.edit || selectedVersion?.draftStatus === null) &&
                          selectedVersion.voucherTemplates[0].type !== 'global_contract' && (
                            <Button onClick={() => exportData(selectedVersion.voucherTemplates)}>
                              Vorlage exportieren
                            </Button>
                          )}
                      </div>
                      <div className="flex">
                        {template.type === 'global_contract' ? (
                          <div className="max-w-[300px] border rounded overflow-clip bg-gray-100 relative">
                            {!isLoadingVoucher ? (
                              previewURLVoucher ? (
                                <img src={previewURLVoucher} alt="Global Voucher Preview" />
                              ) : (
                                <div className="text-red-600 h-20 flex items-center justify-center text-xs m-6">
                                  Vorschau nicht verfügbar
                                </div>
                              )
                            ) : (
                              <Spinner className="w-16 h-16 text-black mx-auto mt-6 mb-6" />
                            )}
                            <small className="m-2 block text-center text-black font-bold">
                              Zentrale Gutscheinvorlage
                            </small>
                          </div>
                        ) : (
                          <Preview template={template} onSelect={() => {}} />
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div>
                    <div className="flex flex-col gap-3 mb-3 ">
                      <div className="font-bold text-lg  text-center">Noch kein Gutschein ausgewählt</div>
                      {userCan.edit && (
                        <>
                          <Button $primary onClick={assignDefaultVoucher}>
                            Standardgutschein benutzen
                          </Button>
                          <Button $secondary onClick={makeVoucher}>
                            Neuen Gutschein erstellen
                          </Button>
                          <Button $secondary onClick={uploadVoucher}>
                            <ArrowDownOnSquareIcon className="w-4 h-4" /> Vorlage importieren
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </Form.Item.Body>
            </Form.Item>
          )}

          <Form.Item className={(!userCan.edit || selectedVersion?.draftStatus === null) && 'pointer-events-none'}>
            <Form.Item.Label>Rechtstexte</Form.Item.Label>
            <Form.Item.Body>
              <MultiSelectInput
                control={form.control}
                name="legalTemplates"
                transformItem={(item) => ({ name: item.label, id: item.value })}
                badgeLabel={(item) => item.name}
                items={legalTemplates
                  ?.filter((templ) => {
                    // if (templ.name.includes('TLG$markdown')) debugger;
                    if (
                      form
                        .watch('legalTemplates')
                        ?.some((template) => template?.id?.id === templ.id || template.id === templ.id)
                    )
                      return false;
                    if (String(membershipTemplate?.backendId).length > 3) return true;
                    if (!templ.name.includes('$')) return true;
                    return templ.name.startsWith(membershipTemplate?.backendId);
                  })
                  .map((item) => {
                    let [backend, name] = item?.name?.split('$');
                    if (!name) {
                      name = backend;
                      backend = 'global';
                    }
                    name = name?.replace('markdown-', ' ');

                    return {
                      label: (
                        <span>
                          <Badge className="text-xs">{backend}</Badge> {name}
                        </span>
                      ) as any,
                      value: item,
                    };
                  })}
                placeholder="Rechtstexte hinzufügen"
              />
            </Form.Item.Body>
          </Form.Item>

          <Form.Item className={(!userCan.edit || selectedVersion?.draftStatus === null) && 'pointer-events-none'}>
            <Form.Item.Label>PDF-Anhänge</Form.Item.Label>
            <Form.Item.Body>
              <Controller
                name="attachments"
                control={form.control}
                render={({ field }) => (
                  <AssetPickerMulti
                    view="list"
                    group="attachments"
                    value={field.value}
                    onChange={field.onChange}
                    placeholder="Anhänge auswählen"
                  />
                )}
              />
            </Form.Item.Body>
          </Form.Item>
        </Form>
        {(selectedVersion?.draftStatus !== null || userCan.edit) && (
          <div className="flex justify-end mt-4">
            <Button loading={pending} onClick={form.handleSubmit(onSubmit)} $primary>
              Speichern
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

function MultiSelectInput({
  control,
  name,
  transformItem,
  badgeLabel,
  items,
  placeholder,
}: {
  control: Control<any>;
  transformItem: (item: any) => any;
  placeholder: string;
  name: string;
  badgeLabel: (item, items) => ReactNode;
  items: { label: string; value: number }[];
}) {
  return (
    <div>
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <div>
              <div className="flex flex-wrap gap-2 mb-3">
                {field.value?.map((item, index) => {
                  let backend, name;
                  [backend, name] = item._id ? item.name?.split('$') : item.id.name?.split('$');
                  if (!name) {
                    name = backend;
                    backend = 'global';
                  }
                  name = name?.replace('markdown-', ' ');

                  return (
                    <Badge hasX onX={() => field.onChange(field.value.filter((i) => i !== item))} key={index}>
                      {name}
                    </Badge>
                  );
                })}
              </div>
              <SimpleSelect
                value={''}
                placeholder={placeholder}
                onChange={(e) => {
                  if (!field.value.some((f) => f.id === e.id)) {
                    field.onChange([...field.value, transformItem(items.find((i) => i.value === e))]);
                  }
                }}
                items={items}
              />
            </div>
          );
        }}
      />
    </div>
  );
}
