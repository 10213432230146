import React, { useEffect } from 'react';
import useBackends from '../hooks/useBackends';
import Input from './Input';
import SimpleSelect from './SimpleSelect';
type BackendSelectProps = {
  /** Label for empty value, defaults to "Alle Backends" */
  defaultLabel?: string;
  /** Placeholder when nothing is selected */
  placeholder?: string;
  /** Prefix for backend item labels */
  prefix?: string;
  /** current value (backendID) */
  value?: string;
  /** onChange callback */
  onChange?: (value: string) => void;
  /** extra classes for SimpleSelect */
  className?: string;
  /** if true, there will be no selectable option for an empty value */
  excludeEmpty?: boolean;

  listIfEmpty?: boolean;
  setIfOnlyOne?: boolean;
  filter?: (backend: string) => boolean;
  /** used to persist Selection between Navigation */
  persistAs?: string;
};

function BackendSelect(props: BackendSelectProps) {
  const {
    defaultLabel = 'Alle Backends',
    prefix = '',
    value,
    onChange,
    className,
    excludeEmpty,
    listIfEmpty = false,
    setIfOnlyOne = false,
    placeholder = 'Backend wählen...',
    filter,
  } = props;
  const { backends } = useBackends();

  const backendItems = [
    ...(!excludeEmpty ? [{ label: defaultLabel, value: null }] : []),
    ...Object.entries(backends || {}).map(([value, label]) => ({ label: `${prefix}${label}`, value })),
  ].filter((item) => (filter ? filter(item?.value) : true));

  useEffect(() => {
    const list = Object.keys(backends || {});
    if (setIfOnlyOne && list?.length === 1 && !value) {
      onChange(list[0]);
    }
  }, [backends, setIfOnlyOne, value, onChange]);

  if (Object.keys(backends || {}).length <= 1) {
    return listIfEmpty ? <Input type="text" disabled defaultValue={backends?.[value]} /> : null;
  }

  return (
    <SimpleSelect
      persistAs={props.persistAs}
      items={backendItems}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={className}
    />
  );
}

// just do a global search with "<BackendSelect" to see example usage
// all you need is "backends" + "setBackends" which are provided by useBackends or useSharedResource

export default BackendSelect;
