import React from 'react';
import { Link, useNavigate, useRouteError } from 'react-router';
import Button from './Button';
import Info from './Info';

export function ErrorBoundary() {
  const error = useRouteError();
  console.dir(error);
  const navigate = useNavigate();
  // the response json is automatically parsed to
  // `error.data`, you also have access to the status

  return (
    <div className="h-screen">
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-indigo-600">Fehler {error?.status}</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            {error?.statusText || 'Oops!'}
          </h1>
          {(error?.data?.sorry || error.message)?.includes('Failed to fetch dynamically imported module') && (
            <Info className="mt-6 text-base leading-7 text-gray-600">
              Es ist wahrscheinlich eine neue Version der App verfügbar. Bitte lade die Seite neu, um die aktuellste
              Version zu erhalten
            </Info>
          )}
          <p className="mt-6 text-base leading-7 text-gray-600">{error?.data?.sorry || error.message}</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Button $primary onClick={() => navigate(-1)}>
              Zurück
            </Button>
            <Link to={`/`}>
              <Button $secondary>Zurück zur Startseite</Button>
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
}
