import { OMTContext } from '@/hooks/onlineMembershipTemplates/omtContext';
import { ReadyStateContext } from '@/hooks/onlineMembershipTemplates/useReadyState';
import { useDSB } from '@/hooks/useDSB';
import useFeedback from '@/hooks/useFeedback';
import useNotifications from '@/hooks/useNotifications';
import usePrompt from '@/hooks/usePrompt';
import useSdk from '@/hooks/useSdk';
import useUser from '@/hooks/useUser';
import { classNames } from '@/util/classNames';
import { translate } from '@/util/translate';
import { ClockIcon, EnvelopeIcon, EyeIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router';
import useSWR from 'swr';
import Badge from '../Badge';
import Button from '../Button';
import Card from '../Card';
import Info from '../Info';
import Input from '../Input';
import { CommentPill } from '../InternalComments';
import Modal from '../Modal';
import SimpleSelect from '../SimpleSelect';
import Smart from '../SmartForm';
import Spinner from '../Spinner';
import Tooly from '../Tooly';
import { BookingPreview } from './BookingPreview';
import { LegalPreview } from './LegalPreview';
import { WebPreview } from './WebPreview';

const items = [
  { label: 'Testung offen', value: 'open' },
  { label: 'Korrektur notwendig', value: 'feedback' },
  { label: 'Freigegeben', value: 'closed' },
];
/** Shows the three Steps to check the Abo before Sending to Customer */
export default function ReleaseForm({ Next, Prev }) {
  const {
    readyState,
    stateMachineService,
    initMachine,
    state,
    relevant,
    setReadyState,
    canNext,
    feedbackForThisState,
    pendingReadyState,
  } = useContext(ReadyStateContext);

  const {
    membershipTemplate,
    completeState,
    selectedVersion,
    mutate,
    mutateVersionState,
    isAddon,
    isDSBUser,
    allowedTransitions,
  } = useContext(OMTContext);
  const user = useUser();
  const [search, setSearch] = useSearchParams();

  const { post } = useDSB();
  const { withFeedback, pending } = useFeedback();
  const { PromptProvider, promptUser } = usePrompt();
  const notifications = useNotifications();

  async function sendTestMail() {
    // send testmail to email as if you booked the contract
    let email;
    try {
      email = await promptUser('An welche Email soll die Test Mail gesendet werden?', user.email);
    } catch (err) {
      return;
    }
    withFeedback(
      async () => {
        await post(`admin/membership-templates/version/${selectedVersion.id}/test-email-contract`, {
          email,
        });
      },
      {
        success: 'Test-Mail gesendet...',
        error: (e) => `Fehler beim Versenden ${e}`,
      },
    );
  }

  const feedbackDone = useMemo(() => {
    const commentIds = Object.values(readyState?.[feedbackForThisState] || {}).reduce((a, b) => {
      return [...a, ...Object.values(b || {}).flat()];
    }, []);
    const comments = selectedVersion?.comments?.filter((c) => commentIds.includes(c.id));
    return comments?.length === 0 || comments?.every((c) => c.metadata?.done);
  }, [readyState, selectedVersion, feedbackForThisState]);

  const [testBooking, setTestBooking] = useState('');
  function bookTestMembership() {
    withFeedback(
      async () => {
        setTestBooking(null);
        const testBooking = await post(`admin/membership-templates/version/${selectedVersion.id}/test-hector`, {});
        setTestBooking(testBooking.id);
      },
      {
        success: 'Testbuchung wurde angestoßen...',
        error: (e) => {
          setTestBooking(null);
          setSearch((prev) => {
            prev.delete('bookingpreview');
            return prev;
          });

          return `Fehler beim Versenden `;
        },
      },
    );
  }

  const { api } = useSdk();
  const { data: lastTest, mutate: mutateLastTest } = useSWR(
    api && selectedVersion ? 'lastBooking' + selectedVersion.id : null,
    async () => {
      const res = (
        await api.entryList('membership_template_version_test', {
          membershipTemplateVersion: selectedVersion.id,
        })
      ).getFirstItem();

      return res;
    },
    { revalidateOnFocus: false, revalidateOnMount: true },
  );

  const [skipTest, setSkipTest] = useState(false);
  const [liveComment, setLiveComment] = useState('');
  const { withFeedback: liveFeedback, pending: livePending } = useFeedback();
  const manualLive = useCallback(async () => {
    liveFeedback(
      async () => {
        if (allowedTransitions.includes('Live')) {
          selectedVersion.hectorSettings.readyState = {
            dsb: { web: { result: 'skipped' }, legal: { result: 'skipped' }, booking: { result: 'skipped' } },
            zmv: { web: { result: 'skipped' }, legal: { result: 'skipped' }, booking: { result: 'skipped' } },
            customer: { web: { result: 'skipped' }, legal: { result: 'skipped' }, booking: { result: 'skipped' } },
          };

          await selectedVersion.save();
          await post(`admin/membership-templates/${selectedVersion?.id}/state`, {
            transition: 'Live',
            payload: `{system} ${liveComment}`,
          });

          await mutate();
          stateMachineService.stop();
          stateMachineService.start();
          initMachine();

          setSkipTest(false);
        } else {
          alert('Transition nicht erlaubt');
        }
      },
      { success: 'Kommentar gespeichert', error: 'Kommentar konnte nicht gespeichert werden' },
    );
  }, [liveFeedback, api, liveComment, selectedVersion]);

  return (
    <div className="flex flex-col gap-6 ">
      <PromptProvider config={{}} />
      {(state?.matches('persist') || pendingReadyState) && (
        <div className="bg-black/50 fixed flex w-full h-full top-0 left-0 z-[99999] items-center justify-center">
          <Spinner />
        </div>
      )}

      {!isAddon && (
        <Card className="grid grid-cols-2 gap-3 justify-between items-end p-6">
          <div className="flex flex-col gap-3">
            <h1 className="text-lg mb-0 font-medium">Preise und Leistungen</h1>
            <small className="text-sm font-normal text-gray-500">Überprüfung der Mitglied werden Konfig</small>
            <StateBadges section="web" />
          </div>

          {selectedVersion?.draftStatus !== 'Entwurf' && (
            <div className="flex flex-col gap-3">
              <WebPreview
                selectedVersion={selectedVersion}
                membershipTemplate={membershipTemplate}
                open={search.get('webpreview') === 'true'}
              />
              {feedbackDone && (
                <>
                  <Button
                    $secondary
                    onClick={() =>
                      setSearch((prev) => {
                        prev.set('webpreview', 'true');
                        return prev;
                      })
                    }
                  >
                    <EyeIcon className="w-4 h-4 mr-3" /> Preise & Leistungen checken
                  </Button>
                  {selectedVersion?.draftStatus !== null && (
                    <SimpleSelect
                      value={readyState?.[relevant]?.web?.result}
                      onChange={(v) => {
                        setReadyState({ type: 'status', value: v, tester: user.email, section: 'web' });
                      }}
                      items={items}
                    />
                  )}
                </>
              )}
            </div>
          )}
          {feedbackDone ? <CurrentComments section="web" /> : <CurrentFeedback section="web" />}
        </Card>
      )}
      <Card className="grid grid-cols-2 gap-3 justify-between items-end p-6">
        <div className="flex flex-col gap-3">
          <h1 className="text-lg mb-0 font-medium">E-Mail, Verträge und Rechtstexte</h1>
          <small className="text-sm font-normal text-gray-500">Überprüfe bitte die Dokumente und E-Mail</small>
          <StateBadges section="legal" />
        </div>
        {selectedVersion?.draftStatus !== 'Entwurf' && (
          <div className="grid grid-cols-[1fr_1fr] gap-3">
            {feedbackDone && (
              <>
                <LegalPreview
                  membershipTemplate={membershipTemplate}
                  selectedVersion={selectedVersion}
                  open={search.get('legalpreview') === 'true'}
                />
                <Button loading={pending} onClick={sendTestMail} $secondary>
                  <EnvelopeIcon className="w-4 h-4 mr-3" /> Testmail senden
                </Button>
                <Button
                  onClick={() =>
                    setSearch((prev) => {
                      prev.set('legalpreview', 'true');
                      return prev;
                    })
                  }
                  $secondary
                >
                  <EyeIcon className="w-4 h-4 mr-3" /> Verträge & Rechtstexte checken
                </Button>
                {selectedVersion?.draftStatus !== null && (
                  <div className="col-span-1 col-start-2">
                    <SimpleSelect
                      value={readyState?.[relevant]?.legal?.result}
                      onChange={(v) =>
                        setReadyState({ type: 'status', value: v, tester: user.email, section: 'legal' })
                      }
                      items={items}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {feedbackDone ? <CurrentComments section="legal" /> : <CurrentFeedback section="legal" />}
      </Card>
      <Card className="grid grid-cols-2 gap-3 justify-between items-end p-6">
        <div className="flex flex-col gap-3">
          <h1 className="text-lg mb-0 font-medium flex gap-2">
            Hector Buchungen
            <Tooly placement="right" label="Hier werden nur die Konfigurationen getestet und nicht die Buchbarkeit.">
              <InformationCircleIcon className="h-4 w-4" />
            </Tooly>
          </h1>
          <small className="text-sm font-normal text-gray-500">Überprüfung der Hector Konfiguration</small>
          <StateBadges section="booking" />
        </div>
        {selectedVersion?.draftStatus !== 'Entwurf' && (
          <div className="flex flex-col gap-3">
            <BookingPreview
              selectedVersion={selectedVersion}
              testBookingId={testBooking}
              setTestBooking={setTestBooking}
              open={!!testBooking}
            />
            {feedbackDone && (
              <>
                {!selectedVersion.mainAbo ? (
                  <Info>Bitte Hauptabo in den Hector Einstellungen hinterlegen</Info>
                ) : (
                  <Button
                    onClick={async () => {
                      if (!selectedVersion.mainAbo) {
                        return;
                      }
                      bookTestMembership();
                      setSearch((prev) => {
                        prev.set('bookingpreview', 'true');
                        return prev;
                      });
                    }}
                    $secondary
                  >
                    <EyeIcon className="w-4 h-4 mr-3" />
                    <span className="flex gap-2">Testbuchung checken {pending && <Spinner className="w-4 h-4" />}</span>
                  </Button>
                )}

                {lastTest && (
                  <>
                    <Button
                      $secondary
                      onClick={() => {
                        setTestBooking(lastTest.id);

                        setSearch((prev) => {
                          prev.set('bookingpreview', 'true');
                          return prev;
                        });
                      }}
                    >
                      <ClockIcon className="w-4 h-4 mr-3" /> Vorheriger Test anzeigen
                    </Button>
                    <small
                      className="text-right hover:underline cursor-pointer"
                      onClick={() => {
                        setSearch((prev) => {
                          prev.set('prevTest', 'true');
                          return prev;
                        });
                      }}
                    >
                      Test Log anzeigen
                    </small>
                    <Modal
                      open={search.get('prevTest') === 'true'}
                      className="w-full max-w-[80%]"
                      onClose={() =>
                        setSearch((prev) => {
                          prev.delete('prevTest');
                          return prev;
                        })
                      }
                    >
                      <div className="flex items-center justify-between pb-6">
                        <span className="text-lg font-medium">Vorheriger Test</span>
                        <Button.Action
                          onClick={() =>
                            setSearch((prev) => {
                              prev.delete('prevTest');
                              return prev;
                            })
                          }
                        >
                          <XMarkIcon className="w-4 h-4" />
                        </Button.Action>
                      </div>
                      <Smart.Form defaultValues={lastTest} onSubmit={() => {}}>
                        <Smart.Input disabled label="Status" name="result" />
                        <Smart.Date disabled label="Erstellt" name="created" />
                        <Smart.Control
                          label="Log"
                          name="log"
                          render={({ field }) => {
                            return (
                              <pre className="bg-gray-100 dark:bg-gray-800 rounded-lg p-2 border max-h-[250px] overflow-auto">
                                {JSON.stringify(field.value, null, 2)}
                              </pre>
                            );
                          }}
                        />
                        <Smart.Control
                          label="Add Customer Command"
                          name="addCustomerCommand"
                          render={({ field }) => {
                            return (
                              <pre className="bg-gray-100 dark:bg-gray-800 rounded-lg p-2 border max-h-[250px] overflow-auto">
                                {JSON.stringify(field.value, null, 2)}
                              </pre>
                            );
                          }}
                        />
                      </Smart.Form>
                    </Modal>
                  </>
                )}
                {selectedVersion?.draftStatus !== null && (
                  <SimpleSelect
                    value={readyState?.[relevant]?.booking?.result}
                    onChange={(v) =>
                      setReadyState({ type: 'status', value: v, section: 'booking', tester: user.email })
                    }
                    items={items}
                  />
                )}
              </>
            )}
          </div>
        )}
        {feedbackDone ? <CurrentComments section="booking" /> : <CurrentFeedback section="booking" />}{' '}
      </Card>
      <div className="flex flex-start justify-end">
        {canNext ? <Next /> : <Prev />}

        {isDSBUser && selectedVersion?.draftStatus == 'In Vorbereitung' && (
          <>
            <Button
              $disabled={!Object.values(completeState || {}).every((v) => v)}
              $danger
              onClick={() => setSkipTest(true)}
              className="ml-3"
            >
              Testrunden überspringen
            </Button>
            <Modal open={skipTest}>
              <div className="flex justify-between">
                <h2>Achtung du bist dabei das Abo ohne Test Live zustellen!!</h2>
                <XMarkIcon onClick={() => setSkipTest(false)} className="w-4 cursor-pointer h-4" />
              </div>
              <p>
                Du überspringst so wichtige Tests und evtl. stellst du ein defektes Abo live.
                <br /> Da du das Abo ohne Freigabe des Kunden live stellst gib bitte einen Kommentar ein
              </p>
              <div className="mt-6 pt-6 border-t">
                <Input type="text" value={liveComment} onChange={(e) => setLiveComment(e.target.value)} />
                <div className="flex justify-end mt-4">
                  <Button
                    $danger
                    placeholder="Kommentar eingeben"
                    $disabled={liveComment.length <= 3}
                    loading={livePending}
                    onClick={manualLive}
                  >
                    Freigeben & Test überspringen
                  </Button>
                </div>
              </div>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
}

const StateBadges = ({ section }) => {
  const { readyState, relevant } = useContext(ReadyStateContext);

  return (
    <div className="flex flex-col justify-start items-start gap-3">
      {Object.keys(readyState || {}).map((key) => (
        <div className="flex gap-2" key={key + section}>
          <small>
            {translate(key, {
              dsb: 'Abo-Entwurf',
              zmv: 'Interne Testung',
              customer: 'Kundenfreigabe',
            })}
          </small>
          <Badge
            className={classNames('text-xs outline-offset-2 outline-1', relevant === key ? 'outline' : 'opacity-50')}
            theme={translate(readyState?.[key]?.[section]?.result, {
              open: 'yellow',
              feedback: 'red',
              closed: 'green',
              skipped: 'gray',
            })}
          >
            {translate(readyState?.[key]?.[section]?.result, {
              open: 'Offen',
              feedback: 'Korrektur notwendig',
              closed: 'Freigegeben',
              skipped: 'Übersprungen',
            })}
          </Badge>
        </div>
      ))}
    </div>
  );
};

export const CurrentComments = ({ section }) => {
  const { selectedVersion, mutate } = useContext(OMTContext);
  const { readyState, relevant, state } = useContext(ReadyStateContext);

  const comments = useMemo(() => {
    const commentIds = Object.values(selectedVersion?.hectorSettings?.readyState?.[relevant]?.[section] || {}).reduce(
      (a, b) => [...a, ...b],
      [],
    );
    return selectedVersion?.comments.filter((c) => commentIds.includes(c.id));
  }, [state, selectedVersion]);

  return (
    <div className="col-span-2 border-t mt-6 pt-6">
      <div className="text- font-bold mb-3">Aktuelle Korrektur: </div>
      <ul role="list" className="w-full">
        {comments?.length > 0
          ? comments?.map((comment, index) => (
              <li key={comment.id}>
                <div className="relative pb-6">
                  {index !== comments.length - 1 ? (
                    <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                  ) : null}
                  <div className="relative flex items-start space-x-3">
                    <CommentPill comment={comment as any} onChange={mutate} />
                  </div>
                </div>
              </li>
            ))
          : 'Noch keine Korrektur vorhanden'}
      </ul>
    </div>
  );
};

export const CurrentFeedback = ({ section }) => {
  const { selectedVersion, mutate } = useContext(OMTContext);
  const { readyState, feedbackForThisState } = useContext(ReadyStateContext);
  const commentIds = Object.values(readyState?.[feedbackForThisState]?.[section] || {}).reduce(
    (a, b) => [...a, ...b],
    [],
  );

  const comments = selectedVersion?.comments?.filter((c) => commentIds.includes(c.id));
  if (!comments?.length) return null;

  return (
    <div className="col-span-2 mb-3 border p-4">
      <div className="text- font-bold mb-3">Korrektur Kommentare von {feedbackForThisState}:</div>
      <ul role="list" className="w-full">
        {comments?.length > 0
          ? comments?.map((comment, index) => (
              <li key={comment.id}>
                <div className="relative pb-6">
                  {index !== comments.length - 1 ? (
                    <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                  ) : null}
                  <div className="relative flex items-start space-x-3">
                    <CommentPill comment={comment as any} onChange={mutate} />
                  </div>
                </div>
              </li>
            ))
          : 'Noch keine Korrektur vorhanden'}
      </ul>
    </div>
  );
};
