import Button from '@/components/Button';
import CalendarRange from '@/components/CalendarRange';
import CalendarRangeInput from '@/components/CalendarRangeInput';
import Form from '@/components/Form';
import Info from '@/components/Info';
import Modal from '@/components/Modal';
import Smart from '@/components/SmartForm';
import Spinner from '@/components/Spinner';
import { ToggleInput } from '@/components/Toggle';
import { useDiscountCodes } from '@/hooks/useDiscounts';
import { classNames } from '@/util/classNames';
import dayjs from '@/util/dayjs';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Description } from '@radix-ui/react-dialog';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

export default function FilterCodesModal({
  show,
  headline,
  setShow,
  form,
}: {
  show: boolean;
  headline: string;
  setShow: (show: boolean) => void;
  form: ReturnType<typeof useForm>;
}) {
  const getCodes = useDiscountCodes();

  return (
    <Modal open={show} onClose={() => setShow(false)}>
      <div className="flex gap-3 items-baseline justify-between">
        <h2 className="text-xl">{headline}</h2>
        <Button.Action onClick={() => setShow(false)}>
          <XMarkIcon className="h-5 w-5 cursor-pointer text-gray-400" />
        </Button.Action>
      </div>
      <div className="flex flex-col gap-3">
        <Smart.Form form={form} onSubmit={() => {}}>
          <div className="border rounded p-4 flex flex-col gap-2">
            <Smart.Radio
              label="Gedruckt"
              $first
              name="printState"
              items={[
                { title: 'Gedruckt', description: '', value: true },
                { title: 'Nicht gedruckt', description: '', value: false },
                { title: 'Egal', description: '', value: null },
              ]}
            />
            {form.watch('printState') !== null && (
              <Smart.Control
                label="im Zeitraum"
                name="printed"
                render={({ field }) => <CalendarRangeInput value={field.value} onChange={field.onChange} />}
              />
            )}
          </div>
          <div className="border rounded p-4 flex flex-col gap-2">
            <Smart.Radio
              label="Exportiert"
              $first
              name="issuedState"
              items={[
                { title: 'Exportiert', description: '', value: true },
                { title: 'Nicht exportiert', description: '', value: false },
                { title: 'Egal', description: '', value: null },
              ]}
            />
            {form.watch('issuedState') !== null && (
              <Smart.Control
                label="im Zeitraum"
                name="exported"
                render={({ field }) => <CalendarRangeInput value={field.value} onChange={field.onChange} />}
              />
            )}
          </div>
          <div className="border rounded p-4 flex flex-col gap-2">
            <Smart.Radio
              label="Eingelöst"
              $first
              name="redeemedState"
              items={[
                { title: 'Eingelöst', description: '', value: true },
                { title: 'Nicht eingelöst', description: '', value: false },
                { title: 'Egal', description: '', value: null },
              ]}
            />
            {form.watch('redeemedState') !== null && (
              <Smart.Control
                label="im Zeitraum"
                name="redeemed"
                render={({ field }) => <CalendarRangeInput value={field.value} onChange={field.onChange} />}
              />
            )}
          </div>
        </Smart.Form>
      </div>
    </Modal>
  );
}
