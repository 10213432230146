import { OMTContext } from '@/hooks/onlineMembershipTemplates/omtContext';
import { useAddons } from '@/hooks/useAppsite';
import useFeedback from '@/hooks/useFeedback';
import useSdk from '@/hooks/useSdk';
import { classNames } from '@/util/classNames';
import dayjs from '@/util/dayjs';
import { DiscountCampaign } from '@entrecode/dm-types/types/DiscountCampaign';
import EntryResource from 'ec.sdk/lib/resources/publicAPI/EntryResource';
import React, { useContext } from 'react';
import Button from '../Button';
import Smart from '../SmartForm';
import Spinner from '../Spinner';
import { useForm } from 'react-hook-form';

const SellingForm = () => {
  const { membershipTemplate: mt, selectedVersion } = useContext(OMTContext);
  const { withFeedback, pending } = useFeedback();
  const membershipTemplate = mt as DiscountCampaign & EntryResource;
  const { api } = useSdk();
  const { data: addons } = useAddons();

  const form = useForm({
    defaultValues: {
      images: membershipTemplate.images,
      name: membershipTemplate.name,
      description: membershipTemplate.description,
      activatedForSale: membershipTemplate.activatedForSale,
      discountPrice: selectedVersion?.hectorSettings.discountPrice,
      dateStart: membershipTemplate.dateStart,
      dateEnd: membershipTemplate.dateEnd,
      sellingStart: membershipTemplate.sellingStart,
      sellingEnd: membershipTemplate.sellingEnd,
      tags: membershipTemplate.tags,
      skipSEPA: !!selectedVersion?.hectorSettings.voucherConfig?.skipSEPA,
      addons: addons
        ?.filter((addon) => selectedVersion?.hectorSettings.addons?.includes(addon.id))
        .reduce((acc, addon) => ({ ...acc, [addon.backendId]: addon }), {}),
    },
  });

  if (membershipTemplate.type === 'addon' && !(addons && selectedVersion)) return <Spinner />;
  return (
    <div>
      <Smart.Form
        key={selectedVersion?.id}
        form={form}
        onSubmit={(values) => {
          withFeedback(
            async () => {
              membershipTemplate.name = values.name;
              membershipTemplate.description = values.description;
              membershipTemplate.images = values.images;
              membershipTemplate.activatedForSale = values.activatedForSale;
              membershipTemplate.dateStart = dayjs(values.dateStart).isValid()
                ? dayjs(values.dateStart).format('YYYY-MM-DD')
                : null;
              membershipTemplate.dateEnd = dayjs(values.dateEnd).isValid()
                ? dayjs(values.dateEnd).format('YYYY-MM-DD')
                : null;
              membershipTemplate.sellingStart = dayjs(values.sellingStart).isValid()
                ? dayjs(values.sellingStart).format('YYYY-MM-DD')
                : null;
              membershipTemplate.sellingEnd = dayjs(values.sellingEnd).isValid()
                ? dayjs(values.sellingEnd).format('YYYY-MM-DD')
                : null;

              if (values.skipSEPA !== selectedVersion?.hectorSettings.voucherConfig?.skipSEPA) {
                selectedVersion.hectorSettings.voucherConfig = {
                  skipSEPA: values.skipSEPA,
                };
                await selectedVersion.save();
              }

              // if (selectedVersion) {
              //   selectedVersion.hectorSettings.discountPrice = +String(values.discountPrice.toFixed(2)).replace(
              //     '.',
              //     '',
              //   );
              //   await selectedVersion.save();
              // }

              membershipTemplate.tags = [];
              for (const tag of values.tags) {
                if (typeof tag === 'string') {
                  const newTag = await api.createEntry('discount_campaign_tag', { tag: tag });
                  membershipTemplate.tags.push(newTag);
                } else {
                  membershipTemplate.tags.push(tag);
                }
              }

              await membershipTemplate.save();
            },
            {
              success: 'Erfolgreich gespeichert',
              error: 'Fehler beim Speichern',
            },
          );
        }}
      >
        <div className={classNames(!membershipTemplate.activatedForSale && 'opacity-50 pointer-events-none')}>
          <Smart.Toggle
            label={
              <>
                Gutschein verkaufen?
                <br />
                <small>Kann nachträglich nur deaktiviert werden.</small>
              </>
            }
            name="activatedForSale"
          />
        </div>
        <Smart.Toggle
          label="SEPA Eingabe überspringbar?"
          primaryLabel="SEPA-Lastschriftmandat ist überspringbar."
          secondaryLabel="Wenn diese Option aktiviert ist, ist die Mitgliedschaft ohne SEPA-Mandat möglich. "
          name="skipSEPA"
        />
        <Smart.File label="Bild" group="settings-images" name="images" />
        <Smart.Input label="Name" name="name" />
        <Smart.Textarea label="Beschreibung" name="description" />
        <div className="border-t pt-5 opacity-50 pointer-events-none">
          <Smart.Price
            cents
            label={
              <>
                Gutschein Preis
                <br />
                <small>Kann nachträglich nicht verändert werden.</small>
              </>
            }
            name="discountPrice"
          />
        </div>
        {form.watch('activatedForSale') && <Smart.Date label="Kaufbar ab" subline="Test" name="sellingStart" />}
        {form.watch('activatedForSale') && <Smart.Date label="Kaufbar bis" name="sellingEnd" />}
        <Smart.Date label="Einlösbar ab" name="dateStart" />
        <Smart.Date label="Einlösbar bis" name="dateEnd" />

        <Smart.TagSelect label="Tags" name="tags" model="discount_campaign_tag" />
        {membershipTemplate.type === 'addon' && <Smart.AddonSelect label="Addons" name="addons" />}
        <div className="flex sticky bottom-0 bg-white dark:bg-gray-600 p-4 justify-end">
          <Button $primary loading={pending}>
            Speichern
          </Button>
        </div>
      </Smart.Form>
    </div>
  );
};

export default SellingForm;
