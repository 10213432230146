import { euroToCent } from '@/util/currency';
import { CalendarIcon, CurrencyEuroIcon } from '@heroicons/react/24/outline';
import { ClipboardDocumentIcon } from '@heroicons/react/24/solid';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import tw from 'tailwind-styled-components';
import { classNames } from '../util/classNames';

import TextareaAutosize from 'react-textarea-autosize';
import Button from './Button';
import Editable from './Editable';
import { hectorIntervals } from './OnlineMembershipTemplates/hectorIntervals';

const Input: any = forwardRef<any>((props: any, ref) => {
  const cleanProps = useMemo(() => {
    const p = { ...props };
    delete p.formState;
    delete p.className;

    return p;
  }, [props]);
  return (
    <input
      ref={ref}
      {...cleanProps}
      className={classNames(
        `block shadow-sm 
        focus:ring-indigo-500 focus:border-indigo-500 
        sm:text-sm
        disabled:bg-gray-200 
        disabled:dark:bg-gray-900 
        border-gray-300 dark:border-gray-500 rounded-md 
        dark:bg-gray-700`,
        !['radio', 'checkbox'].includes(props.type) && 'w-full',
        props.className,
      )}
    />
  );
});

Input.Debounced = forwardRef<any>((props: any, ref) => {
  const { onChange, ...rest } = props;
  const [value, setValue] = useState(props.value);
  const [timer, setTimer] = useState<any>();
  const handleChange = (e) => {
    setValue(e.target.value);
    clearTimeout(timer);
    setTimer(setTimeout(() => onChange?.(e), 500));
  };
  return (
    <>
      <Input ref={ref} onChange={handleChange} {...rest} value={value} />
    </>
  );
});

Input.TimeInterval = forwardRef<any>((props: any, ref) => {
  const input = useRef(null);
  const select = useRef(null);
  const hidden = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => hidden.current, [hidden]);

  const options = props.overrideOptions || [
    { label: 'Tag/e', value: 'D' },
    { label: 'Woche/n', value: 'W' },
    { label: 'Monat/e', value: 'M' },
    { label: 'Jahr/e', value: 'Y' },
  ];

  const p = useMemo(() => {
    const { overrideOptions, ...rest } = props;
    return rest;
  }, [props]);

  const onCompose = (inputValue, selectValue) => `P${inputValue}${selectValue}`;
  const handleChange = () => {
    const composedValue = onCompose(Math.abs(input.current.value), select.current.value);
    Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set.call(hidden.current, composedValue);
    hidden.current.dispatchEvent(new Event('change', { bubbles: true }));
  };

  const hiddenValue = hidden.current?.value;
  useEffect(() => {
    const [, inputValue, selectValue] = hidden.current.value.split(/(\d+)/);
    if (inputValue && selectValue) {
      input.current.value = inputValue;
      select.current.value = selectValue;
    }
  }, [hidden, hiddenValue]);

  return (
    <div>
      <input className="hidden" ref={hidden} {...p} />
      <div className="relative rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="text-gray-500 sm:text-sm">
            <CalendarIcon className="w-4 h-4" />
          </span>
        </div>
        <input
          type="number"
          ref={input}
          onChange={handleChange}
          className="block w-full dark:bg-gray-700 rounded-md border-gray-300 dark:border-gray-500 pl-9 pr-24 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="0"
        />
        <div className="absolute inset-y-0 right-0 flex items-center">
          <label htmlFor="currency" className="sr-only">
            Currency
          </label>
          <select
            onChange={handleChange}
            ref={select}
            className="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7 text-gray-500 dark:text-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            {options?.map((option: any) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
});

const Price = forwardRef((props, ref) => {
  return (
    <div className="relative rounded-md shadow-sm">
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <span className="text-gray-500 sm:text-sm">
          <CurrencyEuroIcon className="w-4 h-4" />
        </span>
      </div>

      <NumericFormat
        placeholder="–"
        onChange={props.onChange}
        onBlur={props.onBlur}
        name={props.name}
        getInputRef={ref}
        // getInputRef={ref => { if (ref) ref.value = hidden.current?.value }}
        className="block w-full dark:bg-gray-700 rounded-md border-gray-300 dark:border-gray-500 pl-9 pr-24 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        decimalSeparator=","
        suffix={' €'}
        decimalScale={2}
      />
    </div>
  );
});

Input.Price = Price;

const PriceInterval = ({
  price,
  interval,
  overrideOptions,
}: {
  price: any;
  interval: any;
  overrideOptions?: { label: string; value: string }[];
}) => {
  const { ref: priceRef, ...priceProps } = price;
  const { ref: intervalRef, ...intervalProps } = interval;

  const options = overrideOptions || hectorIntervals;

  const [priceValue, setPriceValue] = useState();

  const hidden = useRef<HTMLInputElement>(null);
  useImperativeHandle(priceRef, () => hidden.current, [hidden]);
  useEffect(() => {
    if (priceValue === undefined) {
      if (hidden.current?.value) {
        //@ts-ignore
        setPriceValue(hidden.current?.value / 100);
      }
    }
  }, [priceValue, hidden]);

  const handleChange = (e) => {
    setPriceValue(() => e.floatValue);
    Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set.call(
      hidden.current,
      euroToCent(e.floatValue),
    );
    hidden.current.dispatchEvent(new Event('change', { bubbles: true }));
  };

  return (
    <div>
      <div className="relative rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="text-gray-500 sm:text-sm">
            <CurrencyEuroIcon className="w-4 h-4" />
          </span>
        </div>
        <input className="hidden" ref={hidden} {...priceProps} type="number" />
        <NumericFormat
          value={priceValue}
          placeholder="–"
          // getInputRef={ref => { if (ref) ref.value = hidden.current?.value }}
          onValueChange={handleChange}
          className="block w-full dark:bg-gray-700 rounded-md border-gray-300 dark:border-gray-500 pl-9 pr-24 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          decimalSeparator=","
          suffix={' €'}
          decimalScale={2}
        />
        <div className="absolute inset-y-0 right-0 flex items-center">
          <label htmlFor="currency" className="sr-only">
            Currency
          </label>
          <select
            ref={intervalRef}
            {...intervalProps}
            className="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7 text-gray-500 dark:text-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option hidden value="">
              einmalig
            </option>
            {options?.map((option: any) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};
Input.PricePerInterval = PriceInterval;

Input.Textarea = forwardRef<any>((props, ref) => {
  return (
    <TextareaAutosize
      minRows={2}
      maxRows={8}
      ref={ref}
      {...props}
      className={classNames(
        `block w-full shadow-sm 
      focus:ring-indigo-500 focus:border-indigo-500 
      sm:text-sm 
      border-gray-300 dark:border-gray-500 rounded-md 
      dark:bg-gray-700`,
        props?.disabled && '!bg-gray-100 dark:!bg-gray-900',
      )}
    />
  );
});

Input.Copy = forwardRef<any>((props: any, ref: any) => {
  const { children, className, ...rest } = props;
  const fieldRef = useRef<any>();
  const [copied, setCopied] = useState(false);
  return (
    <div className="flex items-center">
      <Input
        ref={(r) => {
          fieldRef.current = r;
          if (typeof ref === 'function') ref(r);
        }}
        className={classNames('flex-1', className, 'rounded-r-none')}
        {...rest}
      />
      <Button
        className="rounded-l-none"
        $secondary
        onClick={() => {
          navigator.clipboard.writeText(fieldRef.current?.value);
          setCopied(true);
        }}
      >
        <ClipboardDocumentIcon className={classNames('w-5 h-5', copied && 'text-green-500')} />
      </Button>
    </div>
  );
});

Input.Radio = forwardRef<any>((props: any, ref) => {
  const { children, ...rest } = props;
  return (
    <label className="flex space-x-2 items-center cursor-pointer py-2 px-1">
      <Input ref={ref} type="radio" className="aspect-1" {...rest} />
      <div>{children}</div>
    </label>
  );
});

Input.Select = forwardRef<any>((props: any, ref) => {
  return (
    <select
      {...props}
      ref={ref}
      className={classNames(
        'bg-white dark:bg-gray-700 relative w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm',
        props.className,
      )}
    >
      {props.children}
    </select>
  );
});

Input.InlineLabel = forwardRef<any>((props: any, ref) => {
  const { className, label, $right, ...inputProps } = props;
  const l = (
    <span
      className={classNames(
        'inline-flex items-center px-3 border border-gray-300 bg-gray-50 dark:border-gray-500 dark:bg-gray-600 text-gray-500 dark:text-gray-100 sm:text-sm',
        !$right ? 'rounded-l-md  border-r-0' : 'rounded-r-md  border-l-0',
      )}
    >
      {label}
    </span>
  );
  return (
    <div className={classNames('max-w-lg flex rounded-md shadow-sm', className)}>
      {!$right && l}
      <input
        ref={ref}
        className={classNames(
          'flex-1 block w-full min-w-0 rounded-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 dark:border-gray-500 dark:bg-gray-700',
          !$right ? 'rounded-r-md' : 'rounded-l-md',
        )}
        {...inputProps}
      />
      {$right && l}
    </div>
  );
});

Input.Color = forwardRef<any>((props: any, ref) => {
  return <input type="color" ref={ref} {...props} />;
});

Input.WithIcon = tw.div`relative rounded-md shadow-sm`;
Input.Icon = tw.div`absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none`;
Input.Editable = forwardRef(({ value: valueProp, onSave, placeholder }: any, ref: any) => {
  const [value, setValue] = useState('');
  const isDirty = value !== valueProp;
  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);
  return (
    <Editable showSaveButton={isDirty} onSave={() => onSave?.(value)}>
      <Input
        ref={ref}
        type="text"
        value={value || ''}
        onChange={(e) => setValue(e.target.value)}
        className={classNames(isDirty ? 'rounded-none rounded-l-md' : 'rounded-md')}
        placeholder={placeholder}
      />
    </Editable>
  );
});

export default Input;
