import { OMTContext } from '@/hooks/onlineMembershipTemplates/omtContext';
import { ReadyStateContext } from '@/hooks/onlineMembershipTemplates/useReadyState';
import useFeedback from '@/hooks/useFeedback';
import useSdk from '@/hooks/useSdk';
import { classNames } from '@/util/classNames';
import { PaperAirplaneIcon, XMarkIcon } from '@heroicons/react/24/solid';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Image, Label, Layer, Stage, Tag, Text } from 'react-konva';
import { Html } from 'react-konva-utils';

import Button from '../Button';
import Spinner from '../Spinner';
import useUser from '@/hooks/useUser';

const CommentPreview = ({
  entry,
  image,
  onComment,
  showBack = true,
  name,
}: {
  entry: any;
  image: string;
  name: string;
  showBack?: boolean;
  onComment?: Function;
}) => {
  const { api } = useSdk();
  const stageRef = useRef(null);
  const { withFeedback, pending } = useFeedback();
  const [labels, setLabels] = useState([]);
  const [preview, setPreview] = useState(null);
  const { selectedVersion, mutate } = useContext(OMTContext);
  const [editable, setEditable] = useState(null);

  useEffect(() => {
    if (!selectedVersion || labels.length) return;
    const previosLabels = selectedVersion?.comments
      .filter((comment) => {
        if (comment.metadata?.labels?.length && comment.text.includes(name)) {
          if (!comment.metadata.done) return true;
        }

        return false;
      })
      .filter((p) => {
        return p.metadata.step === relevant;
      })
      .flatMap((comment) => {
        return comment.metadata.labels;
      });

    setLabels(previosLabels);
  }, [selectedVersion]);

  useEffect(() => {
    if (!image) {
      return;
    }
    const i = new window.Image();
    i.src = image;
    const listener = () => {
      setPreview(i);
      i.removeEventListener('load', listener);
    };
    i.addEventListener('load', listener);
  }, [image]);

  const previosComments = [];
  const MAXSIZE = useMemo(() => 800, []);
  const HEIGHT = useMemo(() => 383, []);
  const { relevant, setReadyState } = useContext(ReadyStateContext) as any;
  const user = useUser();

  const width = useMemo(() => (preview ? Math.round(Math.min(MAXSIZE, preview.width)) : 0), [preview, MAXSIZE]);
  const height = useMemo(
    () =>
      preview
        ? Math.round(preview?.width < MAXSIZE ? preview?.height : (preview?.height / preview?.width) * MAXSIZE)
        : 0,
    [preview, MAXSIZE],
  );

  const makeEditable = useCallback(
    (i: number) => {
      const stage = stageRef.current.getStage();
      const target = stage.findOne(`#${i}`);
      setEditable(target);
    },
    [stageRef.current],
  );

  const listener = useCallback(
    ({ key, shiftKey, target: { value } }: any) => {
      if (!key || (key === 'Enter' && !shiftKey)) {
        const index = Number(editable.id());

        setLabels((prev) => {
          const newLabels = [...prev];
          newLabels[index].text = value;
          return newLabels;
        });
        setEditable(null);
      }
    },
    [editable],
  );

  return (
    <div className="relative">
      {showBack && (
        <Button.Action
          className="absolute top-2 right-14"
          $disabled={pending}
          onClick={() => {
            setLabels([]);
            onComment?.();
          }}
        >
          <XMarkIcon className={classNames('w-6 h-6', (pending || !labels.length) && 'opacity-50')} />
        </Button.Action>
      )}
      {selectedVersion?.draftStatus !== null && (
        <Button.Action
          className="absolute z-10 top-5 right-5"
          placement="left"
          tooltip={
            pending
              ? 'Korrekturen werden eingereicht…'
              : !labels.length
              ? 'Keine Korrekturen'
              : 'Korrekturen einreichen'
          }
          $disabled={pending || !labels.length}
          onClick={async () =>
            withFeedback(
              async () => {
                stageRef.current.container().style.transform = 'translateY(0)';
                stageRef.current.y(0);
                stageRef.current.x(0);
                stageRef.current.width(width);
                stageRef.current.height(height);

                const res = await fetch(
                  stageRef.current?.getStage()?.toDataURL({
                    mimeType: 'image/jpeg',
                    quality: 0.7,
                    imageSmoothingEnabled: false,
                  }),
                );
                const blob = await res.blob();

                const formData: FormData = new FormData();
                formData.set('file', blob, `${name}.jpg`);
                formData.set('ignoreDuplicates', 'true');
                const { items: files } = (await api.createDMAssets('comments', formData)) as any;

                const previosLabels = previosComments
                  .filter((p) => {
                    p.metadata.step === relevant;
                  })
                  .flatMap((c) => c.metadata?.labels);

                const newComment: any = await api.createEntry('comment', {
                  text: `{feedback} Neuer Korrektur für "${name}".`,
                  files,
                  metadata: { step: relevant, labels: [...previosLabels, ...labels], email: user.email },
                });

                // for (const previosComment of previosComments) {
                //   entry.comments = entry.comments.filter((c) => c.id !== previosComment.id);
                //   await previosComment.delete();
                // }

                entry.comments.push(newComment as any);
                await entry.save();

                setReadyState({
                  type: 'comment',
                  name,
                  value: newComment.id,
                });

                setLabels([]);
                onComment?.();
                mutate();
              },
              { success: 'Korrekturen eingereicht.', error: 'Korrekturen konnten nicht eingereicht werden.' },
            )
          }
        >
          <PaperAirplaneIcon className={classNames('w-6 h-6', (pending || !labels.length) && 'opacity-50')} />
          {pending && <Spinner className="w-6 h-6 absolute top-2 left-2 right-2 bottom-2" />}
        </Button.Action>
      )}
      <div
        className={classNames(
          'w-full h-96 border border-t-0 overflow-y-auto rounded-b-lg bg-gray-100',
          !preview && 'flex justify-center items-center',
        )}
        onScroll={({ target }: any) => {
          const { scrollTop } = target;
          stageRef.current.container().style.transform = `translateY(${scrollTop}px)`;
          stageRef.current.y(-scrollTop);
        }}
      >
        {!preview ? (
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-400" />
        ) : (
          <div
            style={{
              width,
              height,
              overflow: 'hidden',
              margin: '20px auto',
            }}
          >
            <div className="flex flex-col items-start gap-1 m-1 fixed z-10 opacity-90 pointer-events-none left-8">
              <div className="text-[10px] bg-blue-200 text-gray-800 p-1">
                <strong>Doppelklick auf Bild:</strong> neuer Kommentar
              </div>
              <div className="text-[10px] bg-blue-200 text-gray-800 p-1">
                <strong>Rechtsklick auf Kommentar:</strong> Kommentar löschen
              </div>
              <div className="text-[10px] bg-blue-200 text-gray-800 p-1">
                <strong>Doppelklick auf Kommentar:</strong> Kommentar bearbeiten
              </div>
            </div>
            <Stage width={width} height={HEIGHT} ref={stageRef}>
              <Layer className="relative">
                {!!editable && (
                  <Html
                    transform
                    groupProps={{
                      ...editable.getClientRect(),
                      y: editable.getClientRect().y - stageRef.current.y(),
                    }}
                  >
                    <textarea
                      ref={(ref) => {
                        ref?.focus();
                        ref?.select();
                      }}
                      style={{
                        background: '#eee000',
                        lineHeight: '100%',
                        fontFamily: 'Arial',
                        color: 'black',
                        padding: 6,
                        fontSize: 12,
                        border: 0,
                        boxShadow: 'none',
                        borderRadius: 3,
                        resize: 'none',
                        overflow: 'hidden',
                        width: editable.getText().width(),
                        height: editable.getText().height(),
                      }}
                      defaultValue={editable.getText().text()}
                      onBlur={listener}
                      onKeyDown={listener}
                    />
                  </Html>
                )}
                <Image
                  image={preview}
                  width={width}
                  height={height}
                  onDblClick={({ target, evt }) => {
                    evt.preventDefault();
                    const stage = target.getStage();
                    const { x, y } = stage.getRelativePointerPosition();
                    setLabels((prev) => [
                      ...prev,
                      {
                        x,
                        y,
                        text: 'Neuer Kommentar',
                      },
                    ]);

                    setTimeout(() => makeEditable(labels.length));
                  }}
                />
                {selectedVersion.draftStatus !== null &&
                  labels.map(({ x, y, text }, i) => {
                    return (
                      <Label
                        x={x}
                        y={y}
                        key={i}
                        id={String(i)}
                        draggable
                        onContextMenu={({ evt }) => {
                          evt.preventDefault();
                          if (confirm('Kommentar löschen?')) {
                            setLabels((prev) => {
                              const newLabels = [...prev];
                              newLabels.splice(i, 1);
                              return newLabels;
                            });
                          }
                        }}
                        onDragEnd={({ target }) => {
                          const { x, y } = target.getPosition();
                          setLabels((prev) => {
                            const newLabels = [...prev];
                            newLabels[i].x = x;
                            newLabels[i].y = y;

                            return newLabels;
                          });
                        }}
                      >
                        <Tag
                          fill="#eee000"
                          pointerDirection="down"
                          pointerWidth={12}
                          pointerHeight={8}
                          cornerRadius={3}
                        />
                        <Text
                          text={text}
                          fontFamily="Arial"
                          fontSize={12}
                          padding={6}
                          fill="black"
                          onDblClick={() => makeEditable(i)}
                        />
                      </Label>
                    );
                  })}
              </Layer>
            </Stage>
          </div>
        )}
      </div>
    </div>
  );
};

export default CommentPreview;
