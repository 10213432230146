import useNotifications from '@/hooks/useNotifications';
import { ArrowTopRightOnSquareIcon, ClipboardDocumentIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { Link } from 'react-router';
import Tooly from './Tooly';

/** External Link with icon */
function MultiOptionLink(props: {
  /** URL */
  href: string;
  /** Additional class name */
  className?: string;
  /** Link content */
  children?: React.ReactNode;
  /** fallback content if no href given */
  fallback?: React.ReactNode;

  target?: '_blank' | string;
}) {
  const { href, className, children, fallback = '', target = '_blank' } = props;
  const notifications = useNotifications();
  if (!href) {
    return <>{fallback}</>;
  }
  return (
    <div
      className={`inline-flex items-center gap-3 group text-indigo-600 dark:text-indigo-300 whitespace-nowrap ${
        className || ''
      }`}
    >
      <div className="flex items-center">{children}</div>
      <Link to={href} target={target}>
        <Tooly label="Neues Fenster">
          <ArrowTopRightOnSquareIcon className="w-4 h-4 block opacity-0 group-hover:opacity-100" />
        </Tooly>
      </Link>

      <div
        onClick={(e) => {
          e.stopPropagation();
          navigator.clipboard.writeText(href);
          notifications.emit({
            type: 'success',
            message: 'Link wurde kopiert!',
          });
        }}
      >
        <Tooly label="Kopieren">
          <ClipboardDocumentIcon className="w-4 h-4 block opacity-0 cursor-pointer group-hover:opacity-100" />
        </Tooly>
      </div>
    </div>
  );
}

export default MultiOptionLink;
