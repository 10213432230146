import { classNames } from '@/util/classNames';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import React, { ReactNode } from 'react';
import { Link, useLocation } from 'react-router';
import tw from 'tailwind-styled-components';
import Button from './Button';
import Card from './Card';
import HeaderSettingsToggle from './HeaderSettingsToggle';
import Spinner from './Spinner';
import Warning from './Warning';

const Section: any = tw.section``;

Section.Header = ({ children }: any) => {
  console.warn('Section.Header has been renamed to Section.Head for consistency');
  return (
    <div className="p-3 sm:p-6 border-gray-200 m-auto max-w-screen-xl	bg-white dark:border-gray-600 flex items-center sm:justify-between sticky top-0 dark:bg-gray-900 z-[30]">
      {children}
    </div>
  );
};

Section.Simple = tw.div`py-5 px-8 border-b border-gray-200`;

Section.Caption = tw.div`my-2 max-w-4xl text-sm text-gray-300 dark:text-gray-500`;

Section.Head = tw.div`p-3 sm:p-6 border-gray-200 m-auto max-w-screen-xl bg-white dark:border-gray-600 flex items-center sm:justify-between sticky top-0 dark:bg-gray-900 z-[30] ${(
  p,
) => p.className}`;
Section.HeadWhite = tw.div`py-5 px-8 border-gray-200 dark:border-gray-600 border-b flex items-center sm:justify-between sticky top-0 bg-white dark:bg-gray-900 z-[30]`;

Section.Heading = tw.h2`m-0 text-xl leading-6 tracking-[-0.01em] font-medium text-gray-900 dark:text-gray-200`;

Section.Center = tw.div`flex justify-center`;

Section.HeadingToggle = ({
  children,
  settingsType,
  depends,
}: {
  children: ReactNode;
  settingsType: string;
  depends?: string;
}) => {
  return (
    <h2 className="m-0 text-lg leading-6 font-medium text-gray-900 dark:text-gray-200 flex items-center">
      {children}
      <HeaderSettingsToggle type={settingsType} depends={depends} />
    </h2>
  );
};

Section.Body = tw.div`px-6 py-3`;

// alternatives for more flexible styling
const ContainerOutter = tw.div`py-5 px-6 m-auto max-w-screen-xl	${(p) => p.className}`;
Section.Container = ({ children, containerClass, ...props }) => {
  return (
    <ContainerOutter className={classNames(containerClass, window.HO_EMBED && '!px-0 !py-0')}>
      <div {...props}>{children}</div>
    </ContainerOutter>
  );
};

const DynamicContainer = ({
  children,
  isLoading,
  error,
  ...props
}: {
  children: ReactNode;
  isLoading: boolean;
  error?: any;
}) => {
  return error ? (
    <Warning label="Ups! Etwas ist schiefgelaufen">{error.detail}</Warning>
  ) : (
    <Section.Container {...props}>
      {isLoading ? <Spinner className="w-14 h-14 self-center m-auto mt-12" /> : children}
    </Section.Container>
  );
};
Section.DynamicContainer = DynamicContainer;

Section.Divider = tw.div`border-gray-200 dark:border-gray-600`;
Section.Sticky = tw.div`sticky top-0 bg-white dark:bg-gray-900 z-[31]`;

Section.Menu = tw.div`flex`;

Section.BackButton = ({ location, disabled = false, onDisabledClick }) => {
  const { state } = useLocation();
  return (
    <div className="flex">
      {disabled ? (
        <div
          onClick={onDisabledClick}
          className="flex mr-3 cursor-pointer outline-none opacity-50 items-center gap-3 p-3 hover:bg-slate-200 rounded-lg"
        >
          <ChevronLeftIcon className="w-4 h-4" /> Zurück
        </div>
      ) : (
        <>
          <Link
            to={state?.overrideBack || location || -1}
            replace={true}
            className="flex mr-3 cursor-pointer outline-none items-center gap-3 p-3 hover:bg-slate-200 rounded-lg"
          >
            <ChevronLeftIcon className="w-4 h-4" /> Zurück
          </Link>
        </>
      )}
      <div className="-my-5 border-gray-200" />
    </div>
  );
};

Section.Sub = tw.h3`text-lg leading-6 font-medium text-gray-900 dark:text-gray-200 !m-0 !mb-0`;

export default Section;

export function SectionExample() {
  return (
    <>
      <Section>
        <Section.Head>
          <Section.Heading>Section</Section.Heading>
          <Section.Menu>
            <Button $primary onClick={() => console.log('action')}>
              action
            </Button>
          </Section.Menu>
        </Section.Head>
        <Section.Body className="max-w-3xl">
          <Card>
            <Card.Body>One ore more Sections can be used as the basic skeleton for a page</Card.Body>
          </Card>
        </Section.Body>
      </Section>
    </>
  );
}

export function SectionExample2() {
  return (
    <>
      <Section>
        <Section.Sticky>
          <Section.Container className="max-w-4xl flex justify-between items-center">
            <Section.Heading>Section</Section.Heading>
            <Button $primary onClick={() => console.log('action')}>
              action
            </Button>
          </Section.Container>
          <Section.Divider />
        </Section.Sticky>
        <Section.Container className="max-w-4xl">
          <Card>
            <Card.Body>
              This Section uses Container / Sticky / Divider instead of Head / Body. This enables setting max-w but
              keeping the line full width.
            </Card.Body>
          </Card>
        </Section.Container>
      </Section>
    </>
  );
}
