import useSWR from 'swr';
import { useHasFeature } from './useFeatureFlags';
import useSdk from './useSdk';

export const useConsentCampaings = ({ filter = {} }) => {
  const { api } = useSdk();
  const { hasFeature } = useHasFeature();

  return useSWR(api ? ['consent_campaign', api.shortID] : null, async () => {
    return hasFeature('consent')
      ? api.entryList('consent_campaign', filter)
      : Promise.resolve({
          items: [],
          total: 0,
          count: 0,
          getAllItems: () => [],
          getFirstItem: () => null,
          getItem: (_n) => null,
        });
  });
};
