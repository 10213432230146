import Spinner from '@/components/Spinner';
import { PencilIcon } from '@heroicons/react/24/solid';
import React, { Fragment, forwardRef } from 'react';
import tw from 'tailwind-styled-components';
import { classNames } from '../util/classNames';
import BooleanIcon from './BooleanIcon';
import Button from './Button';

export const Table = ({
  children,
  className,
  shadow = false,
  overflow = false,
  border = true,
  noPadding = false,
}: {
  children: React.ReactNode;
  className?: string;
  shadow?: boolean;
  overflow?: boolean;
  border?: boolean;
  noPadding?: boolean;
}) => (
  <div className={classNames(!noPadding && 'sm:-mx-6 lg:-mx-8', className, !overflow && 'overflow-x-auto')}>
    <div className={classNames('align-middle inline-block min-w-full', !noPadding && 'py-2 sm:px-6 lg:px-8')}>
      <div
        className={classNames(
          border && 'border-b border-gray-200 dark:border-gray-800',
          shadow && 'shadow',
          !overflow && 'overflow-hidden',
        )}
      >
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-800">{children}</table>
      </div>
    </div>
  </div>
);

export const TableDense = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <div className={classNames('overflow-x-auto py-2 align-middle inline-block min-w-full', className)}>
    <div className="shadow overflow-hidden border-b border-gray-200 dark:border-gray-800 sm:rounded-lg relative">
      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-800">{children}</table>
    </div>
  </div>
);
Table.Dense = TableDense;

export const TableFast = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <div className={classNames('py-2 align-middle inline-block w-full lg:p1-8 overflow-x-auto', className)}>
    <div className="shadow border-b border-gray-200 dark:border-gray-800 relative">
      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-800">{children}</table>
    </div>
  </div>
);
Table.Fast = TableFast;

export const TableHead = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <thead className={classNames('dark:bg-gray-800', className)}>
    <tr>{children}</tr>
  </thead>
);
Table.Head = TableHead;

export const TableHeader = tw.th<any>`px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider`;
Table.Header = TableHeader;

export const TableBody = forwardRef(
  ({ children, className }: { children: React.ReactNode; className?: string }, ref: any) => (
    <tbody
      className={classNames('bg-white divide-y divide-gray-200 dark:bg-gray-700 dark:divide-gray-800', className)}
      ref={ref}
    >
      {children}
    </tbody>
  ),
);
Table.Body = TableBody;

export const striped = (i) => (i % 2 === 0 ? 'bg-white dark:bg-gray-700' : 'bg-gray-50 dark:bg-gray-800');
export const hover = 'hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer';

export const TableRow = tw.tr<any>``;
Table.Row = TableRow;

export const TableCell: any = tw.td`text-gray-900 dark:text-gray-200 p-3 text-sm whitespace-nowrap`;
Table.Cell = TableCell;

Table.Cell.Expander = ({
  id,
  set,
  tooltip = ['ausklappen!!', 'einklappen'],
}: {
  id: string | number;
  set: any; // useSet
  tooltip?: [string, string];
}) => (
  <Table.Cell className="w-12 !p-2">
    <Button.Expander
      tooltip={tooltip}
      onClick={(e) => {
        e.stopPropagation();
        set.toggle(id);
      }}
      active={set.has(id)}
      placement="right"
    />
  </Table.Cell>
);

interface ListingTableProps<T> {
  header: Array<string>;
  data: Array<T>;
  isLoading?: boolean;
  rowClick?: (d: T) => void;
  cell: (d: T) => Array<React.ReactNode> | React.ReactNode;
}
export const ListingTable = <T extends unknown>({ header, data, rowClick, cell, isLoading }: ListingTableProps<T>) => {
  //loading -> table spinner
  //!loading ->
  //   count  = 0 -> Empty
  //   count > 0 -> table
  if (isLoading) {
    return (
      <Table>
        <Table.Head>
          {header.map((h, i) => (
            <Table.Header key={i}>{h}</Table.Header>
          ))}
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan={header?.length}>
              <Spinner />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }

  return data?.length > 0 ? (
    <Table>
      <Table.Head className="max-sm:hidden">
        {header.map((h, i) => (
          <Table.Header key={i}>{h}</Table.Header>
        ))}
      </Table.Head>
      <Table.Body>
        {!isLoading ? (
          data?.map((d, i) =>
            cell(d) instanceof Array ? (
              <Table.Row
                className={classNames('cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-600', d.className)}
                key={i}
                onClick={() => rowClick?.(d)}
              >
                {/* default View */}
                {(cell(d) as any).map((c, j) => (
                  <Table.Cell className="max-sm:hidden" key={j}>
                    {c}
                  </Table.Cell>
                ))}

                {/* Responsive View */}
                <Table.Cell className="sm:hidden !border-none" key={i}>
                  {(cell(d) as any).map((c, j) => (
                    <div key={j} className="!border-none grid grid-cols-[1fr_2fr] gap-2">
                      <div className="p-0">{header[j] && <strong>{header[j]}:</strong>}</div>
                      <div className="p-0">{c}</div>
                    </div>
                  ))}
                </Table.Cell>
              </Table.Row>
            ) : (
              <Fragment key={i}>{cell(d)}</Fragment>
            ),
          )
        ) : (
          <Table.Row>
            <Table.Cell colSpan={header?.length}>
              <Spinner />
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  ) : (
    <div className="text-center py-6 bg-gray-50 dark:bg-gray-800 rounded-sm">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-semibold text-gray-900 dark:text-gray-500">Keine Einträge vorhanden</h3>
    </div>
  );
};

export default Table;

// Example to copy paste..

const people = Array(12).fill({
  name: 'Jane Cooper',
  title: 'Regional Paradigm Technician',
  department: 'Optimization',
  role: 'Admin',
  email: 'jane.cooper@example.com',
  image:
    'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
});

export function TableExample() {
  return (
    <Table>
      <Table.Head>
        <Table.Header>Name</Table.Header>
        <Table.Header>Title</Table.Header>
        <Table.Header>E-Mail</Table.Header>
        <Table.Header>Is Princess</Table.Header>
        <Table.Header>
          <span className="sr-only">Edit</span>
        </Table.Header>
      </Table.Head>
      <Table.Body>
        {people.map((person, i) => (
          <Table.Row key={i}>
            <Table.Cell>{person.name}</Table.Cell>
            <Table.Cell>{person.title}</Table.Cell>
            <Table.Cell>{person.email}</Table.Cell>
            <Table.Cell>
              <BooleanIcon value={Math.random() > 0.5} />
            </Table.Cell>
            <Table.Cell className="text-right">
              <Button.Action onClick={() => console.log('edit')} tooltip="Bearbeiten" placement="left">
                <PencilIcon className="w-5 h-5" />
              </Button.Action>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
