import { OMTContext } from '@/hooks/onlineMembershipTemplates/omtContext';
import { useConsentCampaings } from '@/hooks/useConsentCampaings';
import useFeedback from '@/hooks/useFeedback';
import { translate } from '@/util/translate';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Badge from '../Badge';
import Button from '../Button';
import Form from '../Form';
import Input from '../Input';
import SimpleSelect from '../SimpleSelect';

export default function ConsentSelect() {
  const { selectedVersion, userCan } = useContext(OMTContext);

  const form = useForm({
    defaultValues: { ...selectedVersion } as any,
  });

  useEffect(() => {
    form.reset({ ...selectedVersion });
  }, [selectedVersion, form]);

  const { withFeedback, pending } = useFeedback();

  const onSubmit = (values) => {
    withFeedback(
      async () => {
        selectedVersion.consents = values.consents;
        await selectedVersion.save();
      },
      {
        success: 'Erfolgreich gespeichert',
        error: (e) => 'Fehler beim Speichern' + JSON.stringify(e),
      },
    );
  };

  return (
    <div>
      <div className="flex flex-col gap-3">
        <ConsentSelectField disabled={!userCan.edit || selectedVersion.draftStatus === null} form={form} />
      </div>

      <div className="flex p-4 pb-6 bg-white dark:bg-gray-700 sticky bottom-0 justify-end">
        <Button $disabled={!form.formState.isDirty} loading={pending} $primary onClick={form.handleSubmit(onSubmit)}>
          Speichern
        </Button>
      </div>
    </div>
  );
}

export const ConsentSelectField = ({ form, disabled = false, fieldPrefix = '' }) => {
  const { data: consents } = useConsentCampaings({
    filter: {
      type: { search: 'bookContract' },
    },
  });

  if (!consents) {
    return null;
  }

  return (
    <Form.Item.SortableList
      form={form}
      name={fieldPrefix + 'consents'}
      appendLabel="Konsent hinzufügen"
      extraAction={
        disabled ? null : (
          <SimpleSelect
            placeholder="Zustimmung hinzufügen"
            className="w-full max-w-[300px]"
            onChange={(value) => {
              form.setValue(fieldPrefix + 'consents', [...form.watch(fieldPrefix + 'consents'), value], {
                shouldDirty: true,
                shouldTouch: true,
              });
            }}
            items={consents?.items?.map((consent) => ({
              label: (
                <p>
                  <small className="p-1 bg-gray-200 rounded-md">
                    {translate(consent.type, {
                      bookContractCheckbox: 'Checkbox',
                      bookContractAge: 'Alter',
                      bookContract: 'Allgemein',
                    })}
                  </small>{' '}
                  {consent.name}
                </p>
              ),
              value: consent,
            }))}
          />
        )
      }
    >
      {(i) => {
        const consent = form.watch(fieldPrefix + 'consents.' + i);

        return (
          <div>
            <div className="flex  items-center w-full">
              <div className="font-bold">{consent.name} </div>
              <Button.Action
                onClick={() => {
                  // open in new tab
                  window.open(`../../membership/consents/${consent.id}`, '_blank');
                }}
                tooltip="Konfiguration ansehen"
              >
                <ArrowTopRightOnSquareIcon className="w-4 h-4" />
              </Button.Action>
              <Badge>
                {translate(consent.type, {
                  bookContract: 'Standard',
                  bookContractAge: 'Alter',
                  bookContractCheckbox: 'Checkbox',
                })}
              </Badge>
            </div>

            {consent.type === 'bookContractCheckbox' && (
              <Form.Item $first $dense>
                <Form.Item.Label>Label </Form.Item.Label>
                <Input
                  {...form.register(fieldPrefix + `consentSettings.${i}.label`, {})}
                  placeholder={consent.textConfig.MWCheckBoxLabel}
                />
              </Form.Item>
            )}
          </div>
        );
      }}
    </Form.Item.SortableList>
  );
};
