import { useDSB } from '@/hooks/useDSB';
import { classNames } from '@/util/classNames';
import dayjs from '@/util/dayjs';
import { formatPeriod, formatPeriodUnit } from '@entrecode/hectorone-helpers';
import { XMarkIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { useSearchParams } from 'react-router';
import useSWR from 'swr';
import Accordion from '../Accordion';
import Button from '../Button';
import Info from '../Info';
import Modal from '../Modal';
import Spinner from '../Spinner';
import { ListingTable } from '../Table';
import { Html2Image } from './WebPreview';

type TestBookingData = {
  error?: string;
  contracts: [
    {
      id: number;
      customerId: number;
      type: string;
      name: string;
      backendName: string;
      pricePerInterval: number;
      blockUnitsAvailable: number;
      interval: string;
      duration: string;
      renewal: string;
      termination: string;
      begin: string;
      paymentBegin: string;
      accessGranted: string;
      created: string;
      terminationBefore: string;
      templateId: number;
      possibleDateOfEnd: string;
      cancelledOn: string;
      cancelledTo: string;
      isMainContract: boolean;
      entranceFee: number;
      partialFee: number;
      additionalFees: [
        {
          note: string;
          dueDate: string;
          amount: number;
        },
      ];
      limits: [
        {
          type: number;
          name: string;
          available: number;
          interval: string;
        },
      ];
      freeTestingPhaseActivated: boolean;
      isAddon: boolean;
      creationComplete: boolean;
      deleted: boolean;
      followUpContractId: number;
      studioNumber: number;
      testphaseDuration: number;
      testphaseActivation: boolean;
      articles: [
        {
          id: number;
          type: string;
          description: string;
          bookingDate: string;
        },
      ];
      accountSystems: [
        {
          type: string;
          accountSystem: {
            id: number;
            name: string;
            vatRate: number;
          };
        },
      ];
    },
  ];
  bistroBookings: [
    {
      date: string;
      amount: number;
      note: string;
      article: number;
    },
  ];
  membershipAccount: {
    balance: number;
    summary: [
      {
        year: number;
        months: [
          {
            month: number;
            value: number;
            bookingCount: number;
          },
        ];
      },
    ];
  };
  membershipAccountBookings: [
    {
      date: string;
      amount: number;
      note: string;
      paymentType: number;
      contractId: number;
    },
  ];
};

export const BookingPreview = ({ testBookingId, open, selectedVersion, feedbackDisabled = false, setTestBooking }) => {
  const [, setSearch] = useSearchParams();
  const { routeURL } = useDSB();
  const [error, setError] = React.useState(null);
  const [requestCount, setRequestCount] = React.useState(0);

  const getTestResult = async () => {
    const baseUrl = routeURL('admin/membership-templates/test-result/' + testBookingId);
    const res = await fetch(baseUrl);
    // 202 -> try again
    if (res.status === 202) {
      setRequestCount((prev) => prev + 1);
      throw new Error('try again');
    }

    // 200 -> {error: 'error'} -> error ausgeben
    if (res.status === 200) {
      const json = await res.json();
      if (json.error) {
        setError(json.error);
      }
      return json;
    }
  };

  const closeModal = () => {
    setTestBooking(null);
    setRequestCount(0);
    setError(null);
    setSearch((prev) => {
      prev.delete('bookingpreview');
      return prev;
    });
  };

  const {
    data: testBookingData,
    isLoading,
    error: swrError,
  } = useSWR(open && testBookingId ? 'admin/membership-templates/test-result/' : null, getTestResult, {
    errorRetryCount: 4,
    errorRetryInterval: 1000,
  });

  return (
    <Modal open={open} onClose={closeModal} className="w-full max-w-[80%]">
      <div className="flex justify-between mb-6">
        <div>Test Buchung Vorschau</div>
        <Button.Action onClick={closeModal}>
          <XMarkIcon className="w-5 h-5" />
        </Button.Action>
      </div>
      {requestCount >= 4 && <Info>Testbuchung konnte nicht abgeschlossen werden</Info>}
      {error ? (
        <Info>{error}</Info>
      ) : testBookingData?.membershipAccount ? (
        <Accordion>
          <>
            {testBookingData?.contracts?.map((contract) => (
              <Accordion.Item key={contract.id}>
                <Accordion.Head>{contract.name}</Accordion.Head>
                <Html2Image
                  disabled={selectedVersion.draftStatus === null || feedbackDisabled}
                  html={contract.terms}
                  entry={selectedVersion}
                  name={`Testbuchung ${contract.name}`}
                >
                  <Accordion.Body>
                    <div className="flex flex-col gap-6 pt-6">
                      <DenseTable
                        headline="Abodetails"
                        data={[
                          [
                            { label: 'Abo', value: contract.name },
                            { label: 'Backend', value: contract.backendName },
                            { label: 'Abotyp', value: contract.type },
                          ],
                          [
                            { label: 'Abobeginn', value: formatDate(contract.begin) },
                            contract.type !== 'BlockUnits' && {
                              label: 'Aufnahmegebühr',
                              value: contract.entranceFee?.toFixed(2) + '€',
                            },
                            contract.type !== 'BlockUnits' && {
                              label: 'Beitrag',
                              value: contract.pricePerInterval?.toFixed(2) + '€',
                            },
                            contract.type !== 'BlockUnits' && {
                              label: 'Restbeitrag',
                              value: contract.partialFee?.toFixed(2) + '€',
                            },
                            contract.type !== 'BlockUnits' && {
                              label: 'Zahlungsintervall',
                              value: `pro ${contract.interval ? formatPeriodUnit(contract.interval) : '–'}`,
                            },
                            contract.type === 'BlockUnits' && {
                              label: 'Einheiten',
                              value: `${contract.blockUnitsAvailable}x`,
                            },
                          ].filter(Boolean),
                        ]}
                      />

                      <DenseTable
                        headline="Testphase & Vertragsdetails"
                        data={[
                          [
                            { label: 'Zugang ab (Start Testphase)', value: formatDate(contract.accessGranted) },
                            contract.type !== 'BlockUnits' && {
                              label: 'Dauer der Testphase',
                              value: `${contract.testphaseDuration} Tage`,
                            },
                            {
                              label: 'Zahlungsbeginn (Ende der Testphase)',
                              value: formatDate(contract.paymentBegin),
                            },
                          ].filter(Boolean),
                          [
                            {
                              label: 'Vertragsbeginn (Start der Vertraglaufzeit)',
                              value: formatDate(contract.begin),
                            },
                            contract.type !== 'BlockUnits' && {
                              label: 'Abolaufzeit',
                              value: contract.duration ? formatPeriod(contract.duration) : '-',
                            },
                            { label: 'Nächstmögliches Vertragsende', value: formatDate(contract.possibleDateOfEnd) },
                            contract.type !== 'BlockUnits' && {
                              label: 'Verlängerung',
                              value: contract.renewal ? formatPeriod(contract.renewal) : '-',
                            },
                          ].filter(Boolean),
                        ]}
                      />

                      <DenseTable
                        headline="Artikelbuchung"
                        data={[
                          contract.articles.length
                            ? contract.articles.map((article) => ({
                                value: `<small>Typ: ${article.type}</small> ${formatDate(article.bookingDate) || ' '} `,
                                label: `${article.description || '-'}`,
                              }))
                            : [{ label: 'Keine Buchungen', value: '-' }],
                        ]}
                      />

                      <DenseTable
                        headline="Bistrokonto"
                        data={[
                          testBookingData.bistroBookings.length
                            ? testBookingData.bistroBookings.map((booking) => ({
                                label: `${booking.article}`,
                                value: `<div class="text-right">${booking.amount}€ </br> <small>${
                                  booking.note || '-'
                                }</small>  ${formatDate(booking.date) || ' '}</div> `,
                              }))
                            : [{ label: 'Keine Buchungen', value: '-' }],
                        ]}
                      />
                    </div>
                  </Accordion.Body>
                </Html2Image>
              </Accordion.Item>
            ))}

            <Accordion.Item>
              <Accordion.Head>Abokonto</Accordion.Head>
              <Html2Image entry={selectedVersion} name="Testbuchung Abokonto">
                <Accordion.Body>
                  <div className="flex gap-6 ">
                    <div className="flex flex-col border rounded-lg p-2">
                      <span className="text-center">Jährliches Abos-Kontoblatt</span>
                      {testBookingData?.membershipAccount?.summary.map((summary, i) => (
                        <ListingTable
                          key={i}
                          header={['Monat', 'Jahr', 'Anzahl', 'Betrag']}
                          data={summary.months}
                          cell={(c) => [
                            [
                              'Januar',
                              'Februar',
                              'März',
                              'April',
                              'Mai',
                              'Juni',
                              'Juli',
                              'August',
                              'September',
                              'Oktober',
                              'November',
                              'Dezember',
                            ][c.month - 1],
                            summary.year,
                            c.bookingCount,
                            `${c.value}€`.replace('.', ','),
                          ]}
                        />
                      ))}
                    </div>

                    <div className="flex flex-col border rounded-lg p-2 w-full">
                      <span className="text-center">Monatliches Abos-Kontoblatt</span>
                      <ListingTable
                        header={['Abo', 'Datum', 'Betrag', 'Bemerkung']}
                        data={testBookingData?.membershipAccountBookings}
                        cell={(c) => [
                          <p>
                            {c.contractName} - <small>{c.contractId}</small>
                          </p>,
                          formatDate(c.date),
                          `${c.amount}€`.replace('.', ','),
                          c.note,
                        ]}
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Html2Image>
            </Accordion.Item>
          </>
        </Accordion>
      ) : (
        requestCount < 4 && (
          <div className="flex gap-2 items-center justify-center">
            <Spinner /> {testBookingId ? 'Lade Testbuchung...' : 'Testbuchung angefragt'}
          </div>
        )
      )}
    </Modal>
  );
};

function formatDate(date: string) {
  if (!date) return null;
  return dayjs(date).format('DD.MM.YYYY');
}

export function DenseTable({ headline, data }: { headline: string; data: { label: string; value: any }[][] }) {
  return (
    <div className="bg-transparent">
      <div className="mb-3">{headline}</div>
      <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4 flex gap-6 justify-between items-center">
        {data.map((row, i) => (
          <div key={i} className="flex w-full flex-col">
            {row.map(({ label, value }, i) => (
              <div key={i} className={classNames('flex justify-between p-1 ', i !== row.length - 1 && 'border-b ')}>
                <span className="">{label}</span>
                <span className="font-medium" dangerouslySetInnerHTML={{ __html: value }} />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
